import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CheckValidSessionUseCase,
  RestorePasswordCodeMismatchError,
  RestorePasswordConnectionError,
  RestorePasswordInvalidPasswordError,
  RestorePasswordLimitExceededError,
  RestorePasswordSubmitExpiredCodeError,
  RestorePasswordSubmitUseCase
} from 'src/app/core/core';
import { PasswordValidator, Success } from 'src/app/sdk/sdk';

import { CodeInputComponent } from 'angular-code-input';

@Component({
  selector: 'app-validate-password',
  templateUrl: './validate-password.component.html',
  styleUrls: ['./validate-password.component.scss']
})
export class ValidatePasswordComponent implements OnInit {
  form: FormGroup;
  hasError = false;
  errorMessage = '';
  isLoading = false;
  verification = '';

  @ViewChild('email') emailInput!: ElementRef;
  @ViewChild('validation') validationInput!: CodeInputComponent;
  @ViewChild('newPassword') newPasswordInput!: ElementRef;

  get isFormValid(): boolean {
    return this.form.valid && !!this.verification;
  }

  get emailValue(): string | undefined {
    return this.form.value.email ? this.form.value.email : undefined;
  }

  get passwordsValid(): boolean {
    const mismatch = this.form.errors ? this.form.errors['passwordMissmatch'] : false;
    const policy1 = this.form.get('password')!.errors
      ? this.form.get('password')!.errors!['passwordInvalid']
      : false;
    const policy2 = this.form.get('password_repeat')!.errors
      ? this.form.get('password_repeat')!.errors!['passwordInvalid']
      : false;

    return !mismatch && !policy1 && !policy2;
  }

  get passwordFormField(): AbstractControl | null {
    return this.form.get('password');
  }

  get matchesLength(): boolean {
    return this.passwordFormField?.value?.match(/.{8,}/);
  }

  get matchesNumbers(): boolean {
    return this.passwordFormField?.value?.match(/[0-9]/);
  }

  get matchesLowercase(): boolean {
    return this.passwordFormField?.value?.match(/[a-z]/);
  }

  get matchesUppercase(): boolean {
    return this.passwordFormField?.value?.match(/[A-Z]/);
  }

  get matchesSpecialChars(): boolean {
    return this.passwordFormField?.value?.match(/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/);
  }

  constructor(
    private _restorePasswordSubmitUseCase: RestorePasswordSubmitUseCase,
    private _checkSessionUseCase: CheckValidSessionUseCase,
    private _router: Router,
    private _route: ActivatedRoute,
    private _passwordValidator: PasswordValidator
  ) {
    this.form = new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, this._passwordValid.bind(this)]),
        password_repeat: new FormControl('', [Validators.required, this._passwordValid.bind(this)])
      },
      { validators: this._passwordsMatch }
    );
  }

  async ngOnInit(): Promise<void> {
    const hasSession = await this._checkHasSession();
    if (hasSession) {
      this._router.navigate(['login', 'organizations']);
      return;
    }

    const autofillEmail = this._route.snapshot.queryParamMap.get('email');

    if (autofillEmail) {
      this.form.patchValue({ email: autofillEmail });
      this.validationInput.focusOnField(0);
    } else {
      this.emailInput.nativeElement?.focus();
    }
  }

  onCodeChanged(code: string): void {
    this.verification = code;
  }

  onCodeCompleted(code: string): void {
    this.verification = code;
    this.newPasswordInput.nativeElement?.focus();
  }

  onSubmit(): void {
    if (!this.isFormValid) {
      return;
    }

    if (this.isLoading) {
      return;
    }

    this._restorePasswordSubmit(this.form.value.email, this.form.value.password, this.verification);
  }

  private async _checkHasSession(): Promise<boolean> {
    const result = await this._checkSessionUseCase.execute();
    if (result instanceof Success) {
      return true;
    }

    return false;
  }

  private async _restorePasswordSubmit(email: string, password: string, code: string): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    this.errorMessage = '';
    this.form.disable();

    const response = await this._restorePasswordSubmitUseCase.execute(email, password, code);

    this.isLoading = false;
    this.form.enable();

    if (response instanceof Success) {
      this._router.navigate(['/login'], { queryParams: { email: email } });
      return;
    }

    // We know we have a failure
    this.hasError = true;

    // Set error message based on error type
    if (response.error instanceof RestorePasswordConnectionError) {
      this.errorMessage = $localize`Connection error`;
      return;
    }

    if (response.error instanceof RestorePasswordInvalidPasswordError) {
      this.errorMessage = $localize`Password did not conform with policy`;
      return;
    }

    if (response.error instanceof RestorePasswordCodeMismatchError) {
      this.errorMessage = $localize`Passwords do not match`;
      return;
    }

    if (response.error instanceof RestorePasswordLimitExceededError) {
      this.errorMessage = $localize`Attempt limit exceeded, please try again later`;
      return;
    }

    if (response.error instanceof RestorePasswordSubmitExpiredCodeError) {
      this.errorMessage = $localize`Verification code has expired, please request a new one`;
      return;
    }
  }

  private _passwordsMatch(control: AbstractControl): ValidationErrors | null {
    const newPassword = control.get('password')?.value;
    const newPasswordRepeat = control.get('password_repeat')?.value;

    return newPassword !== newPasswordRepeat ? { passwordMissmatch: true } : null;
  }

  private _passwordValid(control: AbstractControl): ValidationErrors | null {
    const password = control.value;
    const isValid = this._passwordValidator.passwordStreghtIsValid(password);
    return isValid ? null : { passwordInvalid: true };
  }
}
