<button
  [type]="type"
  class="sytex-button"
  [class.active]="active"
  [class.highlighted]="highlighted"
  [class.hover]="hover"
  [disabled]="disabled"
  (click)="onClick($event)">
  <ng-content></ng-content>
  <ng-content select="[badge]"></ng-content>
</button>
<ng-content select="[badge]"></ng-content>
