import {
  AuthorizationException,
  CodeMismatchException,
  ConnectionException,
  CoreException,
  ExpiredCodeException,
  InvalidPasswordException,
  LimitExceededException,
  PasswordRestoreRequiredError,
  UnknownException,
  UserAlreadyExistsException,
  UserNotConfirmedException
} from '../sdk';

export class AmplifyErrorParser {
  parse(error: any): CoreException {
    if (error) {
      switch (error.code) {
        case 'CodeMismatchException':
          return new CodeMismatchException();
        case 'InvalidPasswordException':
          return new InvalidPasswordException();
        case 'LimitExceededException':
          return new LimitExceededException();
        case 'NetworkError':
          return new ConnectionException();
        case 'NotAuthorizedException':
          return new AuthorizationException();
        case 'PasswordResetRequiredException':
          return new PasswordRestoreRequiredError();
        case 'UsernameExistsException':
          return new UserAlreadyExistsException();
        case 'ExpiredCodeException':
          return new ExpiredCodeException();
        case 'UserNotConfirmedException':
          return new UserNotConfirmedException();
      }
    }
    return new UnknownException();
  }
}
