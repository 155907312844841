import {
  CoreError,
  CoreException,
  Failure,
  Response,
  Success,
  UnknownError
} from '@sdk/sdk';

import { Auth } from '@core/core';
import { AuthRepository } from '../repositories/auth.repository';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SaveUserUsecase {
  constructor(private _repository: AuthRepository) {}

  async execute(
    email: string,
    name: string,
    avatar?: string
  ): Promise<Response<Auth, SaveUserError>> {
    const auth = new Auth({ email, name, avatar });
    const result = await this._repository.save(auth);
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(result.value);
  }

  private _exceptionToError(_: CoreException): SaveUserError {
    return new UnknownError();
  }
}

export abstract class SaveUserError extends Error implements CoreError {}
