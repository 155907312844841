import {
  AuthorizationError,
  AuthorizationException,
  ConnectionError,
  ConnectionException,
  CoreError,
  CoreException,
  Failure,
  InvalidPasswordError,
  InvalidPasswordException,
  LimitExceededError,
  LimitExceededException,
  PasswordValidator,
  Response,
  Success
} from '@sdk/sdk';

import { Injectable } from '@angular/core';
import { AuthRepository } from '../repositories/auth.repository';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordUsecase {
  constructor(
    private _repository: AuthRepository,
    private _passwordValidator: PasswordValidator
  ) {}

  async execute(
    oldPassword: string,
    newPassword: string,
    newPasswordRepeat: string
  ): Promise<Response<boolean, ChangePasswordError>> {
    if (newPassword !== newPasswordRepeat) {
      const error = new ChangePasswordMismatchError();
      return new Failure(error);
    }
    if (!this._passwordValidator.passwordStreghtIsValid(newPassword)) {
      const error = new ChangePasswordInvalidPasswordError();
      return new Failure(error);
    }
    const result = await this._repository.changePassword(oldPassword, newPassword);
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(result.value);
  }

  private _exceptionToError(exception: CoreException): ChangePasswordError {
    if (exception instanceof ConnectionException) {
      return new ChangePasswordConnectionError();
    }
    if (exception instanceof AuthorizationException) {
      return new ChangePasswordAuthorizationError();
    }
    if (exception instanceof InvalidPasswordException) {
      return new ChangePasswordInvalidPasswordError();
    }
    if (exception instanceof LimitExceededException) {
      return new ChangePasswordLimitExceededError();
    }
    throw new Error(`Unkown exception`);
  }
}

export abstract class ChangePasswordError extends Error implements CoreError {}

export class ChangePasswordConnectionError extends ConnectionError implements ChangePasswordError {}

export class ChangePasswordAuthorizationError extends AuthorizationError implements ChangePasswordError {}

export class ChangePasswordInvalidPasswordError extends InvalidPasswordError implements ChangePasswordError {}

export class ChangePasswordLimitExceededError extends LimitExceededError implements ChangePasswordError {}

export class ChangePasswordMismatchError extends ChangePasswordError {}
