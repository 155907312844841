import { CoreException, Response } from '@sdk/sdk';
import { Organization } from '../../auth';

export abstract class OrganizationsRepository {
  abstract getAll(): Promise<Response<Organization[], CoreException>>;
  abstract save(
    organization: Organization
  ): Promise<Response<Organization, CoreException>>;
  abstract get(id: string): Promise<Response<Organization, CoreException>>;
}
