import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent implements ControlValueAccessor {
  @HostBinding('class.full-width') @Input() fullWidth?: boolean = false;

  private _placeholder?: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (value: string): void => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = (): void => {};

  @Input() value: string = '';
  @Input() readonly: boolean = false;
  @Input() autofocus: boolean = false;
  @Input() autosize: boolean = false;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() type: string = 'text';
  @Input() label?: string = '';
  @Input() alwaysShowLabel: boolean = false;
  @Input() clear: boolean = false;
  @Input() min: string = '';
  @Input() max: string = '';
  @Input() step: string = '';
  @Input() mask?: string;
  @Input() ngModelOptions: {
    name?: string;
    standalone?: boolean;
    updateOn?: 'change' | 'blur' | 'submit';
  } = {};

  @Input()
  set placeholder(value: string | undefined) {
    this._placeholder = value;
  }
  get placeholder(): string {
    if (this.alwaysShowLabel) {
      return '';
    }
    return this._placeholder ?? this.label ?? '';
  }

  @Output() valueChange = new EventEmitter<string>();
  @Output() changeEvent = new EventEmitter<string>();
  @Output() keydownEvent = new EventEmitter<KeyboardEvent>();

  get inputClasses(): string[] {
    const classes = [];

    if (this.clear) {
      classes.push('clear');
    }

    if (this.size) {
      classes.push(this.size);
    }

    return classes;
  }

  constructor(private _changeDetector: ChangeDetectorRef) {}

  writeValue(value: string): void {
    this.value = value;
    this._changeDetector.markForCheck();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._changeDetector.markForCheck();
  }

  valueChanged(value: string): void {
    this.value = value;
    this.onChange(value);
    this.valueChange.emit(value);
  }

  inputChanged(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    this.changeEvent.emit(input.value);
  }

  keydown(ev: KeyboardEvent): void {
    this.keydownEvent.emit(ev);
  }
}
