import { animate, keyframes, style, transition, trigger } from '@angular/animations';

export const fadeInAnimationTrigger = trigger('fadeInAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('0.25s ease', style({ opacity: 1 }))])
]);

export const fadeOutAnimationTrigger = trigger('fadeOutAnimation', [
  transition(':leave', [style({ opacity: 1 }), animate('0.25s ease', style({ opacity: 0 }))])
]);

export const rightSlideInAnimationTrigger = trigger('rightSlideInAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('0.25s ease', style({ transform: 'translateX(0%)' }))
  ])
]);

export const rightSlideOutAnimationTrigger = trigger('rightSlideOutAnimation', [
  transition(':leave', [
    style({ transform: 'translateX(0%)' }),
    animate('0.25s ease', style({ transform: 'translateX(100%)' }))
  ])
]);

export const leftSlideInAnimationTrigger = trigger('leftSlideInAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('0.25s ease', style({ transform: 'translateX(0%)' }))
  ])
]);

export const leftSlideOutAnimationTrigger = trigger('leftSlideOutAnimation', [
  transition(':leave', [
    style({ transform: 'translateX(0%)' }),
    animate('0.25s ease', style({ transform: 'translateX(-100%)' }))
  ])
]);

export const bounceInAnimationTrigger = trigger('bounceInAnimation', [
  transition(
    ':enter',
    [
      animate(
        '1s ease',
        keyframes([
          style({ transform: 'scale(1.2)' }),
          style({ transform: 'scale(1.2) rotate(16deg)' }),
          style({ transform: 'scale(1.2) rotate(-16deg)' }),
          style({ transform: 'scale(1.2) rotate(16deg)' }),
          style({ transform: 'scale(1.2) rotate(-16deg)' }),
          style({ transform: 'scale(1.2) rotate(16deg)' }),
          style({ transform: 'scale(1.2) rotate(-16deg)' }),
          style({ transform: 'scale(1) rotate(0deg)' })
        ])
      )
    ],
    { delay: '0.2s' }
  )
]);

export const basicAnimations = [
  fadeInAnimationTrigger,
  fadeOutAnimationTrigger,
  rightSlideInAnimationTrigger,
  rightSlideOutAnimationTrigger,
  leftSlideInAnimationTrigger,
  leftSlideOutAnimationTrigger,
  bounceInAnimationTrigger
];
