import { Injectable } from '@angular/core';
import { Organization } from '@core/core';

@Injectable({
  providedIn: 'root'
})
export class OrganizationRedirectHelper {
  getRedirectUrl(
    organizations: Organization[],
    redirect: string
  ): string | null {
    const url = new URL(redirect);
    const orgId = this._getOrgId(url);

    const foundOrgs = organizations.filter(
      organization => organization.url.host === url.host
    );

    if (foundOrgs.length == 1 && !orgId) {
      const organization = foundOrgs[0];
      return this.getOrganizationUrl(organization);
    }

    return redirect;
  }

  getOrganizationUrl(organization: Organization): string {
    return `${organization.url}o/${organization.id}/`;
  }

  getOrganizationFromUrl(
    organizations: Organization[],
    url: string
  ): Organization | undefined {
    return organizations.find(organization =>
      url.startsWith(this.getOrganizationUrl(organization))
    );
  }

  private _getOrgId(url: URL): string {
    // Get organization id from url
    const r = new RegExp(/^\/o\/(\d*)\//);

    if (r.test(url.pathname)) {
      const matches = r.exec(url.pathname);
      return matches![1];
    }

    return '';
  }
}
