import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class CreateUniqueIdUseCase {
  async execute() {
    let device_id = this._getCookie('SYTEX_DEVICE_ID');
    if (!device_id) {
      let uuid = uuidv4();
      device_id = `web|${uuid}`;
      this._setCookie('SYTEX_DEVICE_ID', device_id, 365);
    }
  }

  _getCookie(name: string): string | null {
    const nameEQ = name + '=';
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.startsWith(nameEQ)) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  }

  _setCookie(name: string, value: string, days: number): void {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    // Get the current domain and remove subdomains if any
    const hostParts = window.location.hostname.split('.');
    const domain = hostParts.length > 1 ? `.${hostParts.slice(-2).join('.')}` : window.location.hostname;

    document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=' + domain;
  }
}
