import { HttpErrorResponse } from '@angular/common/http';
import {
  ConnectionException,
  CoreException,
  NotFoundException,
  ServerException,
  UnknownException,
} from '../sdk';

export class ApiErrorParser {
  parse(error: any): CoreException {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 0:
        case 1:
          return new ConnectionException();
        case 404:
          return new NotFoundException();
        case 500:
          return new ServerException();
      }
    }
    return new UnknownException();
  }
}
