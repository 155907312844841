import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() type: string = 'button';
  @Input() tooltip: string = '';
  @Input() active: boolean = false;
  @Input() highlighted: boolean = false;
  @Input() hover: boolean = false;

  @HostBinding('class.disabled') @Input() disabled: boolean = false;

  @Output() btnClick = new EventEmitter<MouseEvent>();

  onClick(ev: MouseEvent): void {
    if (this.disabled) {
      return;
    }
    this.btnClick?.emit(ev);
  }
}
