import { Mapper } from '@sdk/sdk';
import { Auth } from '../auth';
import { LocalAuthMap } from './local-auth.map';

export class LocalAuthMapper extends Mapper<Auth, LocalAuthMap> {
  fromMap(map: LocalAuthMap): Auth {
    return new Auth({
      name: map.name,
      email: map.email,
      avatar: map.avatar,
    });
  }

  toMap(auth: Auth): LocalAuthMap {
    const authMap: LocalAuthMap = {
      name: auth.name,
      email: auth.email,
    };
    if (auth.avatar) {
      authMap.avatar = auth.avatar;
    }
    return authMap;
  }
}
