import {
  CodeMismatchException,
  ConnectionException,
  CoreError,
  CoreException,
  ExpiredCodeException,
  Failure,
  InvalidPasswordError,
  InvalidPasswordException,
  LimitExceededException,
  PasswordValidator,
  Response,
  Success,
  UnknownError
} from '@sdk/sdk';
import {
  RestorePasswordCodeMismatchError,
  RestorePasswordConnectionError,
  RestorePasswordError,
  RestorePasswordInvalidPasswordError,
  RestorePasswordLimitExceededError
} from './restore-password.usecase';

import { Injectable } from '@angular/core';
import { AuthRepository } from '../repositories/auth.repository';

@Injectable({
  providedIn: 'root'
})
export class RestorePasswordSubmitUseCase {
  constructor(
    private _repository: AuthRepository,
    private _passwordValidator: PasswordValidator
  ) {}
  async execute(
    email: string,
    password: string,
    code: string
  ): Promise<Response<boolean, InvalidPasswordError>> {
    if (!this._passwordValidator.passwordStreghtIsValid(password)) {
      const error = new RestorePasswordInvalidPasswordError();
      return new Failure(error);
    }
    const result = await this._repository.restorePasswordSubmit(email, password, code);

    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(true);
  }

  private _exceptionToError(exception: CoreException): RestorePasswordError {
    if (exception instanceof ConnectionException) {
      return new RestorePasswordConnectionError();
    }
    if (exception instanceof InvalidPasswordException) {
      return new RestorePasswordInvalidPasswordError();
    }
    if (exception instanceof CodeMismatchException) {
      return new RestorePasswordCodeMismatchError();
    }
    if (exception instanceof LimitExceededException) {
      return new RestorePasswordLimitExceededError();
    }

    if (exception instanceof ExpiredCodeException) {
      return new RestorePasswordSubmitExpiredCodeError();
    }
    throw new UnknownError();
  }
}

export abstract class RestorePasswordSumitError extends Error implements CoreError {}

export class RestorePasswordSubmitExpiredCodeError
  extends ExpiredCodeException
  implements RestorePasswordSumitError {}
