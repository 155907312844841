import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CodeInputModule } from 'angular-code-input';
import { IconsModule } from '../icons/icons.module';
import { RoutingModule } from '../routing/routing.module';
import { SharedModule } from '../shared/shared.module';
import { AccountListComponent } from './account-list/account-list.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginComponent } from './login.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { PasswordChallengeComponent } from './password-challenge/password-challenge.component';
import { ValidatePasswordComponent } from './validate-password/validate-password.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginFormComponent,
    AccountListComponent,
    OrganizationListComponent,
    ForgotPasswordComponent,
    ValidatePasswordComponent,
    ChangePasswordComponent,
    PasswordChallengeComponent
  ],
  imports: [CodeInputModule, CommonModule, IconsModule, ReactiveFormsModule, RoutingModule, SharedModule],
  exports: [LoginFormComponent, AccountListComponent],
  providers: []
})
export class LoginModule {}
