import { Mapper } from 'src/app/sdk/sdk';
import { Organization } from '../auth';
import { OrganizationMap } from './organization-map';

export class ApiOrganizationMapper
  implements Mapper<Organization, OrganizationMap>
{
  fromMap(map: OrganizationMap): Organization {
    return new Organization({
      name: map.name,
      url: new URL(map.instance),
      avatar: map.avatar,
      id: `${map.organization_id}`,
    });
  }
}
