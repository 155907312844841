export class Auth {
  name: string;
  email: string;
  avatar?: string;

  constructor({ name, email, avatar }: AuthData) {
    this.name = name;
    this.email = email;
    this.avatar = avatar;
  }
}

export interface AuthData {
  name: string;
  email: string;
  avatar?: string;
}
