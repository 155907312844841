import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private _languages!: SystemLanguage[];
  private _defaultLanguage!: SystemLanguage;
  private _currentLanguage!: SystemLanguage;
  private _navigatorLanguage?: SystemLanguage;

  constructor(
    private _location: Location,
    private _cookieService: CookieService
  ) {
    this._languages = [
      { id: 'en', name: 'EN' },
      { id: 'es', name: 'ES' },
      { id: 'pt', name: 'PT' }
    ];

    const webLanguage = navigator.language.split('-')[0];
    this._navigatorLanguage = this._languages.find(language => language.id == webLanguage);
    this._defaultLanguage = this._navigatorLanguage ?? this._languages.find(langauge => langauge.id == 'en')!;

    const language = this._getLanguageFromUrl();
    this._currentLanguage = language ?? this._defaultLanguage;
    this._setLanguageCookie(this._currentLanguage);
  }

  getCurrentLanguage(): SystemLanguage {
    const lookup = this._currentLanguage;
    const retLang = this._languages.find(l => l.id == lookup.id);
    return retLang!;
  }

  setCurrentLanguage(locale: string): void {
    const l = this._languages.find(l => l.id == locale);
    this._currentLanguage = l ?? this._defaultLanguage;
    this._setLanguageCookie(this._currentLanguage);
    this._reloadAfterChange();
  }

  getLanguageList(): SystemLanguage[] {
    return this._languages;
  }

  private _reloadAfterChange(): void {
    window.location.href = this._getFinalRedirectUrl();
  }

  private _getLanguageFromUrl(): SystemLanguage | undefined {
    const urlLanguage = window.location.pathname.split('/')[1];
    return this._languages.find(language => language.id == urlLanguage);
  }

  private _setLanguageCookie(language: SystemLanguage): void {
    this._cookieService.set(environment.i18n.cookie_name, language.id, {
      expires: 365,
      domain: 'sytex.io',
      path: '/'
    });
  }

  private _getFinalRedirectUrl(): string {
    const path = this._location.path();
    return `/${this._currentLanguage.id}${path}`;
  }
}

export interface SystemLanguage {
  id: string;
  name: string;
}
