import { CommonModule } from '@angular/common';
import { CreateAccountComponent } from './create-account/create-account.component';
import { CreateOrganizationComponent } from './create-organization/create-organization.component';
import { CreationComponent } from './creation.component';
import { IconsModule } from '../icons/icons.module';
import { JoinOrganizationComponent } from './join-organization/join-organization.component';
import { JoinRequestSuccessComponent } from './join-request-success/join-request-success.component';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RoutingModule } from '../routing/routing.module';
import { ValidateEmailComponent } from './validate-email/validate-email.component';
import { WelcomeComponent } from './welcome/welcome.component';

@NgModule({
  declarations: [
    CreateAccountComponent,
    CreationComponent,
    ValidateEmailComponent,
    WelcomeComponent,
    JoinOrganizationComponent,
    JoinRequestSuccessComponent,
    CreateOrganizationComponent
  ],
  imports: [
    CommonModule,
    IconsModule,
    ReactiveFormsModule,
    RoutingModule,
  ]
})
export class CreationModule { }
