export abstract class CoreError {}

export class ServerError extends Error implements CoreError {}
export class AuthorizationError extends Error implements CoreError {}
export class InvalidPasswordError extends Error implements CoreError {}
export class PasswordRestoreRequiredError extends Error implements CoreError {}
export class LimitExceededError extends Error implements CoreError {}
export class CodeMismatchError extends Error implements CoreError {}
export class ConnectionError extends Error implements CoreError {}
export class NotFoundError extends Error implements CoreError {}
export class InvalidTokenError extends Error implements CoreError {}
export class InvalidUserError extends Error implements CoreError {}
export class UnknownError extends Error implements CoreError {}
export class ExpiredCodeError extends Error implements CoreError {}
export class UserNotConfirmedError extends Error implements CoreError {}
