import {
  CoreError,
  CoreException,
  Failure,
  InvalidTokenException,
  Response,
  Success,
  UnknownError
} from '@sdk/sdk';

import { AuthRepository } from '../repositories/auth.repository';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckValidSessionUseCase {
  constructor(private _repository: AuthRepository) {}

  async execute(): Promise<Response<boolean, CoreError>> {
    const result = await this._repository.hasValidSession();
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(result.value);
  }

  private _exceptionToError(exception: CoreException): CheckValidSessionError {
    if (exception instanceof InvalidTokenException) {
      return new CheckValidSessionInvalidTokenError();
    }

    return new UnknownError();
  }
}

export abstract class CheckValidSessionError {}
export class CheckValidSessionInvalidTokenError extends CheckValidSessionError {}
