import {
  CoreError,
  CoreException,
  Failure,
  Response,
  Success,
  UnknownError,
  UnknownException
} from '@sdk/sdk';
import { Organization, UserAccount } from '@core/core';

import { Injectable } from '@angular/core';
import { UserAccountsRepository } from '../repositories/user-accounts.repository';

@Injectable({ providedIn: 'root' })
export class SaveUserAccountUsecase {
  constructor(private _repository: UserAccountsRepository) {}

  async execute(
    email: string,
    name: string,
    organization: Organization,
    avatar?: string
  ): Promise<Response<UserAccount, SaveUserAccountError>> {
    const userAccount = new UserAccount({
      email,
      name,
      organization,
      avatar
    });
    const result = await this._repository.save(userAccount);
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(result.value);
  }

  private _exceptionToError(exception: CoreException): SaveUserAccountError {
    if (exception instanceof UnknownException) {
      return new UnknownError();
    }
    throw new Error(`Unkown exception`);
  }
}

export abstract class SaveUserAccountError extends Error implements CoreError {}
