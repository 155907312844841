import {
  CodeMismatchException,
  CoreException,
  Failure,
  Response,
  Success,
  UnknownError
} from '@sdk/sdk';

import { AuthRepository } from '../repositories/auth.repository';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidateEmailUseCase {
  constructor(private _repository: AuthRepository) {}

  async execute(
    email: string,
    code: string
  ): Promise<Response<boolean, ValidateError>> {
    const result = await this._repository.validateEmail(email, code);
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(true);
  }

  private _exceptionToError(exception: CoreException): ValidateError {
    if (exception instanceof CodeMismatchException) {
      return new ValidateCodeMismatchExceptionError();
    }

    throw new UnknownError();
  }
}

export abstract class ValidateError {}
export class ValidateCodeMismatchExceptionError
  extends CodeMismatchException
  implements ValidateError {}
