import {
  ConnectionError,
  ConnectionException,
  CoreError,
  CoreException,
  Failure,
  InvalidPasswordError,
  InvalidPasswordException,
  LimitExceededError,
  LimitExceededException,
  NotFoundException,
  PasswordValidator,
  Response,
  Success
} from '@sdk/sdk';

import { AuthRepository } from '../repositories/auth.repository';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordChallengeUsecase {
  constructor(
    private _repository: AuthRepository,
    private _passwordValidator: PasswordValidator
  ) {}

  async execute(
    newPassword: string,
    newPasswordRepeat: string
  ): Promise<Response<boolean, PasswordChallengeError>> {
    if (newPassword !== newPasswordRepeat) {
      const error = new PasswordChallengeMismatchError();
      return new Failure(error);
    }
    if (!this._passwordValidator.passwordStreghtIsValid(newPassword)) {
      const error = new PasswordChallengeInvalidPasswordError();
      return new Failure(error);
    }
    const result = await this._repository.completeNewPassword(newPassword);
    if (result instanceof Failure) {
      return new Failure(this._exceptionToError(result.error));
    }
    return new Success(result.value);
  }

  private _exceptionToError(exception: CoreException): PasswordChallengeError {
    if (exception instanceof NotFoundException) {
      return new PasswordChallengeNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new PasswordChallengeConnectionError();
    }
    if (exception instanceof InvalidPasswordException) {
      return new PasswordChallengeInvalidPasswordError();
    }
    if (exception instanceof LimitExceededException) {
      return new PasswordChallengeLimitExceededError();
    }

    throw new Error(`Unkown exception`);
  }
}

export abstract class PasswordChallengeError
  extends Error
  implements CoreError {}

export class PasswordChallengeNotFoundError
  extends ConnectionError
  implements PasswordChallengeError {}

export class PasswordChallengeMismatchError
  extends ConnectionError
  implements PasswordChallengeError {}

export class PasswordChallengeConnectionError
  extends ConnectionError
  implements PasswordChallengeError {}

export class PasswordChallengeInvalidPasswordError
  extends InvalidPasswordError
  implements PasswordChallengeError {}

export class PasswordChallengeLimitExceededError
  extends LimitExceededError
  implements PasswordChallengeError {}
