import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle, faXmark } from '@fortawesome/pro-light-svg-icons';
import {
  faAngleRight,
  faCheck,
  faLanguage,
  faMinusCircle,
  faTimes
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowLeft as faArrowLeftSolid,
  faArrowRight as faArrowRightSolid,
  faBuilding as faBuildingSolid,
  faCheckCircle as faCheckCircleSolid,
  faClock as faClockSolid,
  faEnvelope as faEnvelopeSolid,
  faEyeSlash as faEyeSlashSolid,
  faEye as faEyeSolid,
  faKey as faKeySolid,
  faLockAlt as faLockAltSolid,
  faMapMarkerAlt as faMapMarkerAltSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faSearch,
  faShieldCheck as faShieldCheckSolid,
  faSignOut as faSignOutSolid,
  faUserEdit as faUserEditSolid,
  faUserLock as faUserLockSolid,
  faUserMinus as faUserMinusSolid,
  faUserPlus as faUserPlusSolid,
  faUser as faUserSolid
} from '@fortawesome/pro-solid-svg-icons';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    // Light
    library.addIcons(faInfoCircle, faXmark);

    // Regular
    library.addIcons(faAngleRight, faCheck, faTimes, faLanguage, faMinusCircle);

    // Solid
    library.addIcons(
      faArrowLeftSolid,
      faArrowRightSolid,
      faBuildingSolid,
      faCheckCircleSolid,
      faClockSolid,
      faEnvelopeSolid,
      faEyeSolid,
      faEyeSlashSolid,
      faKeySolid,
      faSearch,
      faLockAltSolid,
      faMapMarkerAltSolid,
      faQuestionCircleSolid,
      faShieldCheckSolid,
      faSignOutSolid,
      faUserSolid,
      faUserEditSolid,
      faUserLockSolid,
      faUserMinusSolid,
      faUserPlusSolid
    );
  }
}
