import { RouterModule, Routes } from '@angular/router';

import { AccountListComponent } from '../login/account-list/account-list.component';
import { ChangePasswordComponent } from '../login/change-password/change-password.component';
import { CreateAccountComponent } from '../creation/create-account/create-account.component';
import { CreateOrganizationComponent } from '../creation/create-organization/create-organization.component';
import { CreationComponent } from '../creation/creation.component';
import { ForgotPasswordComponent } from '../login/forgot-password/forgot-password.component';
import { JoinOrganizationComponent } from '../creation/join-organization/join-organization.component';
import { JoinRequestSuccessComponent } from '../creation/join-request-success/join-request-success.component';
import { LoginComponent } from '../login/login.component';
import { LoginFormComponent } from '../login/login-form/login-form.component';
import { NgModule } from '@angular/core';
import { OrganizationListComponent } from '../login/organization-list/organization-list.component';
import { PasswordChallengeComponent } from '../login/password-challenge/password-challenge.component';
import { ValidateEmailComponent } from '../creation/validate-email/validate-email.component';
import { ValidatePasswordComponent } from '../login/validate-password/validate-password.component';
import { WelcomeComponent } from '../creation/welcome/welcome.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    children: [
      {
        path: '',
        component: LoginFormComponent
      },
      {
        path: 'organizations',
        component: OrganizationListComponent
      },
      {
        path: 'accounts',
        component: AccountListComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'restore-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'validate-password',
        component: ValidatePasswordComponent
      },
      {
        path: 'password-challenge',
        component: PasswordChallengeComponent
      }
    ]
  },
  {
    path: 'create',
    component: CreationComponent,
    children: [
      {
        path: 'account',
        component: CreateAccountComponent
      },
      {
        path: 'validate',
        component: ValidateEmailComponent
      },
      {
        path: 'welcome',
        component: WelcomeComponent
      },
      {
        path: 'join',
        component: JoinOrganizationComponent
      },
      {
        path: 'request-success',
        component: JoinRequestSuccessComponent
      },
      {
        path: 'organization',
        component: CreateOrganizationComponent
      },
      { path: '', redirectTo: '/create/account', pathMatch: 'full' }
    ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
  // Empty
}
