import { Response, Success } from 'src/app/sdk/sdk';

import { Injectable } from '@angular/core';
import { UserAccount } from '../../auth';
import { UserAccountsRepository } from '../repositories/user-accounts.repository';

@Injectable({
  providedIn: 'root'
})
export class GetUserAccountsUseCase {
  constructor(private _userAccountRepository: UserAccountsRepository) {}

  async execute(): Promise<Response<UserAccount[], GetUserAccountsError>> {
    const result = await this._userAccountRepository.getAll();
    return new Success(result.value);
  }
}

export abstract class GetUserAccountsError {}
