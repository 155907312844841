import { Component, OnInit, isDevMode } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isDevMode = false;
  hashtag = $localize`MoreConnected`;

  ngOnInit(): void {
    this.isDevMode = isDevMode();
  }
}
