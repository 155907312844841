<div class="step">Paso X de Y</div>
<div class="title" i18n>Create your organization</div>
<div class="subtitle" i18n>
  You can create multiple organizations with your account.
</div>
<form [formGroup]="form">
  <div class="inputs">
    <div class="input-container with-prefix">
      <fa-icon [icon]="['fas', 'building']"></fa-icon>
      <input
        type="text"
        formControlName="name"
        i18n-placeholder
        placeholder="Name" />
    </div>
    <div class="input-container with-prefix">
      <fa-icon [icon]="['fas', 'map-marker-alt']"></fa-icon>
      <input
        type="text"
        formControlName="location"
        i18n-placeholder
        placeholder="Location" />
    </div>
    <div class="input-row">
      <div class="input-container with-prefix">
        <fa-icon [icon]="['far', 'language']"></fa-icon>
        <input
          type="text"
          formControlName="language"
          i18n-placeholder
          placeholder="Default language" />
      </div>
      <div class="input-container with-prefix">
        <fa-icon [icon]="['fas', 'clock']"></fa-icon>
        <input
          type="text"
          formControlName="timezone"
          i18n-placeholder
          placeholder="Time zone" />
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="rounded-button accent">
      <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
      <span i18n>Next</span>
    </button>
  </div>
</form>
