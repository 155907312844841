import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  RegisterConnectionError,
  RegisterError,
  RegisterUseCase,
  RegisterUserAlreadyExistsError
} from '@core/auth/application/usecases/register-user.usecase';
import { CreateUniqueIdUseCase } from '@core/core';
import { Failure, PasswordValidator, Success } from '@sdk/sdk';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  redirectTo: string | null = null;
  name!: string;
  email!: string;
  form: FormGroup;
  isLoading: boolean = false;
  errorMessage: string = '';
  showRestorePassword: boolean = false;

  constructor(
    private _registerUseCase: RegisterUseCase,
    private _router: Router,
    private _route: ActivatedRoute,
    private _passwordValidator: PasswordValidator,
    private _createUniqueIdUseCase: CreateUniqueIdUseCase
  ) {
    this.form = new FormGroup(
      {
        password: new FormControl('', [Validators.required, this._passwordValid.bind(this)]),
        repeatPassword: new FormControl('', [Validators.required, this._passwordValid.bind(this)])
      },
      { validators: this._passwordsMatch }
    );
  }

  get passwordFormField(): AbstractControl | null {
    return this.form.get('password');
  }

  get matchesLength(): boolean {
    return this.passwordFormField?.value?.match(/.{8,}/);
  }

  get matchesNumbers(): boolean {
    return this.passwordFormField?.value?.match(/[0-9]/);
  }

  get matchesLowercase(): boolean {
    return this.passwordFormField?.value?.match(/[a-z]/);
  }

  get matchesUppercase(): boolean {
    return this.passwordFormField?.value?.match(/[A-Z]/);
  }

  get matchesSpecialChars(): boolean {
    return this.passwordFormField?.value?.match(/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/);
  }

  ngOnInit(): void {
    this.redirectTo = this._route.snapshot.queryParamMap.get('redirect');
    const query_name_param = this._route.snapshot.queryParamMap.get('name');
    this.name = query_name_param?.split(' ')[0] ?? '';
    this.email = this._route.snapshot.queryParamMap.get('email')!;
    this._createUniqueIdUseCase.execute();
  }

  async onSubmit(): Promise<void> {
    this.errorMessage = '';
    if (!this.email) {
      return;
    }
    if (this.form.valid) {
      this.isLoading = true;

      const result = await this._registerUseCase.execute(this.email, this.form.value.password, this.name);
      this.isLoading = false;
      if (result instanceof Success) {
        this._router.navigate(['create', 'validate'], {
          queryParamsHandling: 'preserve'
        });
      }

      if (result instanceof Failure) {
        this.showErrorMessage(result.error);
      }
    }
  }

  showErrorMessage(error: RegisterError): void {
    if (error instanceof RegisterUserAlreadyExistsError) {
      this.errorMessage = $localize`User already exists. Please, try to login with your email and password`;
      this.showRestorePassword = true;
    }

    if (error instanceof RegisterConnectionError) {
      this.errorMessage = $localize`Connection error`;
    }
  }

  restorePassword(): void {
    this._router.navigate(['login', 'restore-password'], {
      queryParams: { email: this.email }
    });
    this.showRestorePassword = false;
  }

  private _passwordsMatch(control: AbstractControl): ValidationErrors | null {
    const newPassword = control.get('password')?.value;
    const newPasswordRepeat = control.get('repeatPassword')?.value;

    return newPassword !== newPasswordRepeat ? { passwordMissmatch: true } : null;
  }

  private _passwordValid(control: AbstractControl): ValidationErrors | null {
    const password = control.value;
    const isValid = this._passwordValidator.passwordStreghtIsValid(password);
    return isValid ? null : { passwordInvalid: true };
  }
}
