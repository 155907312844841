import {
  AmplifyErrorParser,
  CoreException,
  Failure,
  InvalidTokenException,
  NotFoundException,
  PasswordRestoreRequiredException,
  Response,
  Success,
  UnknownException
} from '@sdk/sdk';
import { Auth, AuthRepository } from '../auth';

import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Auth as CognitoAuth } from 'aws-amplify';
import { ApiAuthMapper } from './api-auth.mapper';
import { ApiSessionService } from './api-session.service';
import { LocalAuthMapper } from './local-auth.mapper';

@Injectable({
  providedIn: 'root'
})
export class ApiAuthRepository implements AuthRepository {
  amplifyErrorParser = new AmplifyErrorParser();
  sessionService = new ApiSessionService();
  authMapper = new ApiAuthMapper();
  localAuthMapper = new LocalAuthMapper();

  challengeUser?: any;

  async login(email: string, password: string): Promise<Response<Auth, CoreException>> {
    try {
      const result = await CognitoAuth.signIn(email, password);
      if (result?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.challengeUser = result;
        return new Failure(new PasswordRestoreRequiredException());
      }
      const user = this.authMapper.fromMap(result);
      return new Success(user);
    } catch (error) {
      Sentry.captureException(error);
      return new Failure(this._amplifyError(error));
    }
  }

  async restorePassword(email: string): Promise<Response<boolean, CoreException>> {
    try {
      await CognitoAuth.forgotPassword(email);
      return new Success(true);
    } catch (error) {
      return new Failure(this._amplifyError(error));
    }
  }

  async restorePasswordSubmit(
    email: string,
    password: string,
    code: string
  ): Promise<Response<boolean, CoreException>> {
    try {
      await CognitoAuth.forgotPasswordSubmit(email, code, password);
      return new Success(true);
    } catch (error) {
      return new Failure(this._amplifyError(error));
    }
  }

  async save(auth: Auth): Promise<Response<Auth, CoreException>> {
    try {
      const authMap = this.localAuthMapper.toMap(auth);
      localStorage.setItem('sytex-user', JSON.stringify(authMap));
      return new Success(auth);
    } catch (error) {
      return new Failure(new UnknownException(`${error}`));
    }
  }

  async logOut(): Promise<Response<boolean, CoreException>> {
    try {
      await CognitoAuth.signOut();
      localStorage.removeItem('sytex-user');
      return new Success(true);
    } catch (error) {
      return new Failure(this._amplifyError(error));
    }
  }

  async getLoggedUser(): Promise<Response<Auth, CoreException>> {
    try {
      const userStr = localStorage.getItem('sytex-user');
      if (!userStr) {
        return new Failure(new NotFoundException());
      }
      const userMap = JSON.parse(userStr);
      return new Success(this.localAuthMapper.fromMap(userMap));
    } catch (error) {
      return new Failure(new UnknownException(`${error}`));
    }
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<Response<boolean, CoreException>> {
    try {
      const user = await CognitoAuth.currentAuthenticatedUser();
      await CognitoAuth.changePassword(user, oldPassword, newPassword);
      return new Success(true);
    } catch (error) {
      return new Failure(this._amplifyError(error));
    }
  }

  async completeNewPassword(password: string): Promise<Response<boolean, CoreException>> {
    if (!this.challengeUser) {
      return new Failure(new NotFoundException());
    }

    try {
      await CognitoAuth.completeNewPassword(this.challengeUser, password);
      this.challengeUser = null;
      return new Success(true);
    } catch (error) {
      return new Failure(this._amplifyError(error));
    }
  }

  async register(username: string, password: string): Promise<Response<boolean, CoreException>> {
    try {
      const result = await CognitoAuth.signUp({
        username,
        password
      });
      return new Success(true);
    } catch (error) {
      const amplifyError = this._amplifyError(error);
      return new Failure(amplifyError);
    }
  }

  async validateEmail(username: string, code: string): Promise<Response<boolean, CoreException>> {
    try {
      await CognitoAuth.confirmSignUp(username, code);
      return new Success(true);
    } catch (error) {
      return new Failure(this._amplifyError(error));
    }
  }

  async resendCode(username: string): Promise<Response<boolean, CoreException>> {
    try {
      await CognitoAuth.resendSignUp(username);
      return new Success(true);
    } catch (error) {
      return new Failure(this._amplifyError(error));
    }
  }

  async hasValidSession(): Promise<Response<boolean, CoreException>> {
    try {
      await this.sessionService.getToken();
      return new Success(true);
    } catch (error) {
      return new Failure(new InvalidTokenException());
    }
  }

  private _amplifyError(error: any): CoreException {
    return this.amplifyErrorParser.parse(error);
  }
}
