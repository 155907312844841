<div class="step">Paso 1 de 2</div>
<div class="title" i18n>
  Hello {{ name }} 👋, please create your Sytex account.
</div>
<div class="subtitle" i18n>
  Choose your password to access Sytex and all your project activities.
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="inputs">
    <div class="input-container with-prefix">
      <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
      <input
        type="text"
        value="{{ email }}"
        [disabled]="true"
        i18n-placeholder
        placeholder="Email" />
    </div>
    <div class="input-row">
      <div class="input-container with-prefix">
        <fa-icon [icon]="['fas', 'lock-alt']"></fa-icon>
        <input
          type="password"
          formControlName="password"
          i18n-placeholder
          placeholder="Password" />
      </div>
      <div class="input-container with-prefix">
        <fa-icon [icon]="['fas', 'lock-alt']"></fa-icon>
        <input
          type="password"
          formControlName="repeatPassword"
          i18n-placeholder
          placeholder="Repeat password" />
      </div>
    </div>
    <div class="error-display" *ngIf="errorMessage">
      <span class="error-message">{{ errorMessage }}</span>
      <span *ngIf="showRestorePassword" i18n
        >You can try to restore your password by clicking
        <a class="forgot-pass" (click)="restorePassword()">here</a></span
      >
    </div>
  </div>
  <div class="bottom">
    <div class="password-policy">
      <div class="title" i18n>Password security</div>
      <div class="policy">
        <ng-container
          *ngIf="passwordFormField?.value.length > 0; else noPassword">
          <ng-container *ngIf="matchesLength; else notValid">
            <fa-icon
              class="valid"
              [icon]="['far', 'check']"
              [fixedWidth]="true"></fa-icon>
          </ng-container>
        </ng-container>
        <span i18n>Minimum length 8 characters</span>
      </div>
      <div class="policy">
        <ng-container
          *ngIf="passwordFormField?.value.length > 0; else noPassword">
          <ng-container *ngIf="matchesNumbers; else notValid">
            <fa-icon
              class="valid"
              [icon]="['far', 'check']"
              [fixedWidth]="true"></fa-icon>
          </ng-container>
        </ng-container>
        <span i18n>At least 1 number</span>
      </div>
      <div class="policy">
        <ng-container
          *ngIf="passwordFormField?.value.length > 0; else noPassword">
          <ng-container *ngIf="matchesUppercase; else notValid">
            <fa-icon
              class="valid"
              [icon]="['far', 'check']"
              [fixedWidth]="true"></fa-icon>
          </ng-container>
        </ng-container>
        <span i18n>At least 1 uppercase letter</span>
      </div>
      <div class="policy">
        <ng-container
          *ngIf="passwordFormField?.value.length > 0; else noPassword">
          <ng-container *ngIf="matchesLowercase; else notValid">
            <fa-icon
              class="valid"
              [icon]="['far', 'check']"
              [fixedWidth]="true"></fa-icon>
          </ng-container>
        </ng-container>
        <span i18n>At least 1 lowercase letter</span>
      </div>
      <div class="policy">
        <ng-container
          *ngIf="passwordFormField?.value.length > 0; else noPassword">
          <ng-container *ngIf="matchesSpecialChars; else notValid">
            <fa-icon
              class="valid"
              [icon]="['far', 'check']"
              [fixedWidth]="true"></fa-icon>
          </ng-container>
        </ng-container>
        <span i18n>At least 1 special character</span>
      </div>
    </div>
    <div class="buttons">
      <button
        class="rounded-button accent"
        *ngIf="!isLoading"
        [disabled]="!email">
        <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
        <span i18n>Next</span>
      </button>
      <div class="spinner" *ngIf="isLoading"></div>
    </div>
  </div>
</form>
<ng-template #noPassword>
  <fa-icon [icon]="['far', 'angle-right']" [fixedWidth]="true"></fa-icon>
</ng-template>
<ng-template #notValid>
  <fa-icon
    class="invalid"
    [icon]="['far', 'times']"
    [fixedWidth]="true"></fa-icon>
</ng-template>
