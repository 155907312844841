import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '../icons/icons.module';
import { ButtonComponent } from './buttons/button/button.component';
import { InputComponent } from './input/input.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  declarations: [LanguageSelectorComponent, InputComponent, ModalComponent, ButtonComponent],
  imports: [CommonModule, IconsModule, ReactiveFormsModule, FormsModule, PortalModule],
  exports: [LanguageSelectorComponent, InputComponent, ModalComponent, ButtonComponent]
})
export class SharedModule {
  // Empty
}
