import {
  ConnectionError,
  ConnectionException,
  CoreException,
  Failure,
  Response,
  ServerError,
  ServerException,
  Success,
  UnknownError
} from '@sdk/sdk';

import { Injectable } from '@angular/core';
import { Organization } from '../../auth';
import { OrganizationsRepository } from '../repositories/organizations.repository';

@Injectable({
  providedIn: 'root'
})
export class GetOrganizationsUseCase {
  constructor(private _repository: OrganizationsRepository) {}

  async execute(): Promise<Response<Organization[], GetOrganizationsError>> {
    const result = await this._repository.getAll();
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(result.value);
  }

  private _exceptionToError(exception: CoreException): GetOrganizationsError {
    if (exception instanceof ConnectionException) {
      return new GetOrganizationsConnectionError();
    }
    if (exception instanceof ServerException) {
      return new GetOrganizationsServerError();
    }

    return new UnknownError();
  }
}

export abstract class GetOrganizationsError {}

export class GetOrganizationsConnectionError
  extends ConnectionError
  implements GetOrganizationsError {}
export class GetOrganizationsServerError
  extends ServerError
  implements GetOrganizationsError {}
