import { Component, ElementRef, OnInit, ViewChild, isDevMode } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CheckValidSessionUseCase,
  CreateUniqueIdUseCase,
  GetUserAccountsUseCase,
  LogInUseCase,
  LoginAuthorizationError,
  LoginConnectionError,
  LoginPasswordRestoreRequiredError,
  LoginUserNotConfirmedError
} from '@core/core';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Success, UnknownError } from '@sdk/sdk';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  private _redirectTo: string | null = null;

  form: FormGroup;
  hasError = false;
  errorMessage = '';
  isLoading = false;
  initializing = true;
  isDevMode = false;
  passToggleIcon: IconName = 'eye';
  passShown = false;

  @ViewChild('email') emailInput!: ElementRef;
  @ViewChild('password') passInput!: ElementRef;

  public get isFormValid(): boolean {
    return this.form.valid;
  }

  public get emailValue(): string | undefined {
    return this.form.value.email ? this.form.value.email : undefined;
  }

  constructor(
    private _loginUseCase: LogInUseCase,
    private _checkSessionUseCase: CheckValidSessionUseCase,
    private _getUserAccountsUseCase: GetUserAccountsUseCase,
    private _createUniqueIdUseCase: CreateUniqueIdUseCase,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  async ngOnInit(): Promise<void> {
    this.isDevMode = isDevMode();
    this._createUniqueIdUseCase.execute();
    const redirected = await this._handleStartupRedirections();

    this.initializing = false;

    if (redirected) {
      return;
    }

    this._redirectTo = this._route.snapshot.queryParamMap.get('r');
  }

  showHidePassword(): void {
    this.passShown = !this.passShown;
    this.passToggleIcon = this.passShown ? 'eye-slash' : 'eye';
    this.passInput.nativeElement.type = this.passShown ? 'text' : 'password';
  }

  onSubmit(): void {
    if (!this.isFormValid) {
      return;
    }

    if (this.isLoading) {
      return;
    }

    this._login(this.form.value.email, this.form.value.password);
  }

  private async _handleStartupRedirections(): Promise<boolean> {
    const hasSession = await this._checkHasSession();
    if (hasSession) {
      this._router.navigate(['login', 'organizations'], {
        queryParamsHandling: 'merge'
      });
      return true;
    }

    const newLogin = this._route.snapshot.queryParamMap.get('new');
    const autofillEmail = this._route.snapshot.queryParamMap.get('email');

    const stay = newLogin || autofillEmail != null;

    const accountsResult = await this._getUserAccountsUseCase.execute();
    const hasAccountCache = accountsResult.value.length > 0;

    if (hasAccountCache && !stay) {
      this._router.navigate(['login', 'accounts'], {
        queryParamsHandling: 'merge'
      });
      return true;
    }

    if (autofillEmail) {
      this.form.patchValue({ email: autofillEmail });
      this.passInput.nativeElement?.focus();
    } else {
      this.emailInput.nativeElement?.focus();
    }

    return false;
  }

  private async _checkHasSession(): Promise<boolean> {
    const result = await this._checkSessionUseCase.execute();
    if (result instanceof Success) {
      return true;
    }

    return false;
  }

  private async _login(email: string, password: string): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    this.errorMessage = '';
    this.form.disable();

    const response = await this._loginUseCase.execute(email, password);

    this.isLoading = false;
    this.form.enable();

    if (response instanceof Success) {
      this._router.navigate(['organizations'], {
        relativeTo: this._route,
        queryParams: { r: this._redirectTo }
      });
      return;
    }

    // We know we have a failure
    this.hasError = true;

    // Set error message based on error type
    if (response.error instanceof LoginConnectionError) {
      this.errorMessage = $localize`Connection error`;
      return;
    }

    if (response.error instanceof LoginAuthorizationError) {
      this.errorMessage = $localize`Invalid email or password`;
      return;
    }

    if (response.error instanceof LoginPasswordRestoreRequiredError) {
      this._router.navigate(['password-challenge'], {
        relativeTo: this._route,
        queryParams: { r: this._redirectTo }
      });
      return;
    }

    if (response.error instanceof LoginUserNotConfirmedError) {
      this._router.navigate(['create', 'validate'], {
        queryParams: { email: this.form.value.email }
      });

      return;
    }

    if (response.error instanceof UnknownError) {
      this.errorMessage = $localize`Something went wrong. Please try again later. If the problem persists, contact support.`;
      return;
    }
  }
}
