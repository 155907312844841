import { Mapper } from 'src/app/sdk/sdk';
import { Organization } from '../auth';
import { LocalOrganizationMap } from './local-organization.map';

export class LocalOrganizationMapper extends Mapper<
  Organization,
  LocalOrganizationMap
> {
  fromMap(map: LocalOrganizationMap): Organization {
    return new Organization({
      id: map.id,
      name: map.name,
      url: new URL(map.url),
      avatar: map.avatar,
    });
  }

  toMap(organization: Organization): LocalOrganizationMap {
    return {
      id: organization.id,
      name: organization.name,
      url: `${organization.url}`,
      avatar: organization.avatar,
    };
  }
}
