<div class="title" i18n>Restore your password</div>
<div class="subtitle" i18n>Enter your email to start.</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="inputs">
    <div class="input-container with-prefix">
      <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
      <input
        type="email"
        i18n-placeholder
        placeholder="Email"
        formControlName="email"
        autocomplete="email"
        #email />
    </div>
    <a
      class="validation-code"
      routerLink="../validate-password"
      [queryParams]="{ email: emailValue }"
      i18n
      >Already have a validation code</a
    >
  </div>
  <div class="buttons">
    <button
      class="rounded-button"
      type="button"
      *ngIf="!isLoading"
      routerLink="/login"
      [queryParams]="{ email: emailValue }">
      <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
      <span i18n>Go to login</span>
    </button>
    <span class="spacer"></span>
    <button
      class="rounded-button accent"
      *ngIf="!isLoading"
      [disabled]="!isFormValid">
      <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
      <span i18n>Continue</span>
    </button>
    <div class="spinner" *ngIf="isLoading"></div>
  </div>
  <div class="error" *ngIf="hasError">{{ errorMessage }}</div>
</form>
