import { UserAccount } from '@core/core';
import { CoreException, Response } from '@sdk/sdk';

export abstract class UserAccountsRepository {
  abstract getAll(): Promise<Response<UserAccount[], CoreException>>;
  abstract clearAll(): Promise<Response<boolean, CoreException>>;
  abstract save(
    auth: UserAccount
  ): Promise<Response<UserAccount, CoreException>>;
  abstract removeAccount(
    email: string,
    organizationId: string
  ): Promise<Response<boolean, CoreException>>;
}
