import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { KeyBindingMap, createKeybindingsHandler } from 'tinykeys';
import { basicAnimations } from '../animations';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  animations: basicAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private _overlayRef: OverlayRef;

  @Input() minWidth: number = 320;
  @Input() maxWidth: number = 600;
  @Input() disableBackdropClick: boolean = false;
  @Input() disableKeydownEvents: boolean = false;
  @Input() hideHeader: boolean = false;
  @Input() hideCloseButton: boolean = false;
  @Input() keybindings: KeyBindingMap = {
    Escape: ev => {
      ev.preventDefault();
      this.closeModal.emit();
    }
  };

  @Output() closeModal = new EventEmitter<void>();

  @ViewChild(CdkPortal) private _portal: CdkPortal | undefined;

  constructor(private _overlay: Overlay) {
    const positionStrategy = this._overlay.position().global().centerHorizontally().centerVertically();
    const scrollStrategy = this._overlay.scrollStrategies.block();
    const config = new OverlayConfig({
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy
    });
    this._overlayRef = this._overlay.create(config);
  }

  ngOnInit(): void {
    if (!this.disableBackdropClick) {
      this._overlayRef.backdropClick().subscribe(() => {
        this.closeModal.emit();
      });
    }

    if (!this.disableKeydownEvents && this.keybindings) {
      this._overlayRef.keydownEvents().subscribe(createKeybindingsHandler(this.keybindings));
    }
  }

  ngAfterViewInit(): void {
    this._overlayRef.attach(this._portal);
  }

  ngOnDestroy(): void {
    this._overlayRef.detach();
  }
}
