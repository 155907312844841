import { Response, Success } from '@sdk/sdk';

import { AuthRepository } from '../repositories/auth.repository';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogOutUseCase {
  constructor(private _repository: AuthRepository) {}

  async execute(): Promise<Response<boolean, SignOutError>> {
    await this._repository.logOut();
    return new Success(true);
  }
}

export abstract class SignOutError {}
