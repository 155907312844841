import { Auth } from 'aws-amplify';
import { InvalidTokenException } from 'src/app/sdk/sdk';

export class ApiSessionService {
  async getToken(): Promise<string> {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (error) {
      throw new InvalidTokenException();
    }
  }
}
