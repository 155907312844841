import { Injectable } from '@angular/core';
import {
  AuthorizationError,
  AuthorizationException,
  ConnectionError,
  ConnectionException,
  CoreException,
  Failure,
  PasswordRestoreRequiredError,
  PasswordRestoreRequiredException,
  Response,
  Success,
  UnknownError,
  UserNotConfirmedError,
  UserNotConfirmedException
} from '@sdk/sdk';
import * as Sentry from '@sentry/angular';
import { AuthRepository } from '../repositories/auth.repository';
import { SaveUserUsecase } from './save-user.usecase';

@Injectable({
  providedIn: 'root'
})
export class LogInUseCase {
  constructor(
    private _repository: AuthRepository,
    private _saveUserUsecase: SaveUserUsecase
  ) {}
  async execute(email: string, password: string): Promise<Response<boolean, LoginError>> {
    const result = await this._repository.login(email, password);
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    const auth = result.value;
    await this._saveUserUsecase.execute(auth.email, auth.name, auth.avatar);
    return new Success(true);
  }

  private _exceptionToError(exception: CoreException): LoginError {
    if (exception instanceof AuthorizationException) {
      return new LoginAuthorizationError();
    }

    if (exception instanceof ConnectionException) {
      return new LoginConnectionError();
    }

    if (exception instanceof PasswordRestoreRequiredException) {
      return new LoginPasswordRestoreRequiredError();
    }

    if (exception instanceof UserNotConfirmedException) {
      return new LoginUserNotConfirmedError();
    }

    Sentry.captureException(exception);

    throw new UnknownError();
  }
}

export abstract class LoginError {}

export class LoginAuthorizationError extends AuthorizationError implements LoginError {}

export class LoginPasswordRestoreRequiredError extends PasswordRestoreRequiredError implements LoginError {}

export class LoginConnectionError extends ConnectionError implements LoginError {}

export class LoginUserNotConfirmedError extends UserNotConfirmedError implements LoginError {}
