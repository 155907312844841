export class Response<S, F> {
  constructor(public success: S | null, public failure: F | null) {}

  get value(): S {
    if (this.success === undefined || this.success === null) {
      throw new Error('not success found');
    }
    return this.success;
  }

  get error(): F {
    if (this.failure === undefined || this.failure === null) {
      throw new Error('not failure found');
    }
    return this.failure;
  }
}

export class Success<S, F> extends Response<S, F> {
  constructor(success: S) {
    super(success, null);
  }
}

export class Failure<S, F> extends Response<S, F> {
  constructor(failure: F) {
    super(null, failure);
  }

  override get value(): S {
    throw new Error("can't call value from failure");
  }
}
