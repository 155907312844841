import {
  AuthorizationError,
  AuthorizationException,
  CodeMismatchError,
  ConnectionError,
  ConnectionException,
  CoreError,
  CoreException,
  Failure,
  InvalidPasswordError,
  LimitExceededError,
  LimitExceededException,
  Response,
  Success,
  UnknownError
} from '@sdk/sdk';

import { Injectable } from '@angular/core';
import { AuthRepository } from '../repositories/auth.repository';

@Injectable({
  providedIn: 'root'
})
export class RestorePasswordUseCase {
  constructor(private _repository: AuthRepository) {}
  async execute(email: string): Promise<Response<boolean, RestorePasswordError>> {
    const result = await this._repository.restorePassword(email);
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(true);
  }

  private _exceptionToError(exception: CoreException): RestorePasswordError {
    if (exception instanceof ConnectionException) {
      return new RestorePasswordConnectionError();
    }

    if (exception instanceof AuthorizationException) {
      return new RestorePasswordAuthorizationError();
    }

    if (exception instanceof LimitExceededException) {
      return new RestorePasswordLimitExceededError();
    }

    throw new UnknownError();
  }
}

export abstract class RestorePasswordError extends Error implements CoreError {}

export class RestorePasswordConnectionError extends ConnectionError implements RestorePasswordError {}

export class RestorePasswordInvalidPasswordError
  extends InvalidPasswordError
  implements RestorePasswordError {}

export class RestorePasswordCodeMismatchError extends CodeMismatchError implements RestorePasswordError {}

export class RestorePasswordAuthorizationError extends AuthorizationError implements RestorePasswordError {}

export class RestorePasswordLimitExceededError extends LimitExceededError implements RestorePasswordError {}
