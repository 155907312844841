<div class="step">Paso 2 de 2</div>
<div class="title">
  {{ name }} <span i18n>let's validate your account</span>
</div>
<div class="subtitle" i18n>We've sent you an email. Please verify it.</div>
<div class="sent-line">
  <span i18n>Enter the validation code we've sent to</span>
  <strong>{{ email }}</strong>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="inputs">
    <div class="input-container with-prefix">
      <fa-icon [icon]="['fas', 'shield-check']"></fa-icon>
      <input
        type="text"
        formControlName="code"
        i18n-placeholder
        placeholder="Validation code" />
    </div>
    <div class="errorMessage" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
  <div class="buttons">
    <div
      class="resend-code"
      *ngIf="!isLoadingResend"
      (click)="resendCode()"
      i18n>
      Resend code
    </div>
    <div class="spinner" *ngIf="isLoadingResend"></div>
    <button class="rounded-button accent" *ngIf="!isLoadingValidate">
      <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
      <span i18n>Validate</span>
    </button>
    <div class="spinner" *ngIf="isLoadingValidate"></div>
  </div>
</form>
