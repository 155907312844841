import { Component } from '@angular/core';

@Component({
  selector: 'app-join-request-success',
  templateUrl: './join-request-success.component.html',
  styleUrls: ['./join-request-success.component.scss']
})
export class JoinRequestSuccessComponent {
  // Empty
}
