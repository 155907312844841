<div class="step">Paso X de Y</div>
<div class="title" i18n>Join an organization</div>
<div class="subtitle" i18n>Send a invitation request.</div>
<div class="send-line" i18n>
  Enter the email of the organization responsible
</div>
<form [formGroup]="form">
  <div class="inputs">
    <div class="input-container with-prefix">
      <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
      <input
        type="text"
        formControlName="email"
        i18n-placeholder
        placeholder="Email" />
    </div>
  </div>
  <div class="buttons">
    <button class="rounded-button accent" routerLink="../request-success">
      <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
      <span i18n>Request invite</span>
    </button>
  </div>
</form>
