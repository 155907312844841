import { Mapper } from 'src/app/sdk/sdk';
import { Auth } from '../auth';
import { ApiAuthMap } from './api-auth.map';

export class ApiAuthMapper extends Mapper<Auth, ApiAuthMap> {
  fromMap(map: ApiAuthMap): Auth {
    return new Auth({
      name: map.attributes.email,
      email: map.attributes.email,
      avatar: map.attributes.avatar
    });
  }
}
