export class Organization {
  name: string;
  url: URL;
  avatar?: string;
  id: string;

  constructor({ name, url, avatar, id: organizationId }: OrganizationData) {
    this.name = name;
    this.url = url;
    this.avatar = avatar;
    this.id = organizationId;
  }
}

export interface OrganizationData {
  name: string;
  url: URL;
  avatar?: string;
  id: string;
}
