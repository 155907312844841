<div
  class="input-container"
  [ngClass]="inputClasses"
  [class.always-show-label]="alwaysShowLabel"
  [class.has-label]="!!label"
  [class.invalid]="invalid">
  <div class="fake-input">
    <ng-content select="[prefix]"></ng-content>
    @if (autosize) {
      @if (mask) {
        <input
          appAutoSizeInput
          [min]="min"
          [max]="max"
          [step]="step"
          [type]="type"
          [placeholder]="placeholder"
          [readOnly]="readonly"
          [disabled]="disabled"
          [ngModel]="value"
          [ngModelOptions]="ngModelOptions"
          (ngModelChange)="valueChanged($event)"
          (change)="inputChanged($event)"
          (blur)="onTouch()"
          (keydown)="keydown($event)" />
      } @else {
        <input
          appAutoSizeInput
          [min]="min"
          [max]="max"
          [step]="step"
          [type]="type"
          [placeholder]="placeholder"
          [readOnly]="readonly"
          [disabled]="disabled"
          [ngModel]="value"
          [ngModelOptions]="ngModelOptions"
          (ngModelChange)="valueChanged($event)"
          (change)="inputChanged($event)"
          (blur)="onTouch()"
          (keydown)="keydown($event)" />
      }
    } @else {
      @if (mask) {
        <input
          [min]="min"
          [max]="max"
          [step]="step"
          [type]="type"
          [placeholder]="placeholder"
          [readOnly]="readonly"
          [disabled]="disabled"
          [ngModel]="value"
          [ngModelOptions]="ngModelOptions"
          (ngModelChange)="valueChanged($event)"
          (change)="inputChanged($event)"
          (blur)="onTouch()"
          (keydown)="keydown($event)" />
      } @else {
        <input
          [min]="min"
          [max]="max"
          [step]="step"
          [type]="type"
          [placeholder]="placeholder"
          [readOnly]="readonly"
          [disabled]="disabled"
          [ngModel]="value"
          [ngModelOptions]="ngModelOptions"
          (ngModelChange)="valueChanged($event)"
          (change)="inputChanged($event)"
          (blur)="onTouch()"
          (keydown)="keydown($event)" />
      }
    }
    <ng-content select="[suffix]"></ng-content>
    <div class="label" *ngIf="!!label">{{ label }}</div>
  </div>
</div>
