import {
  AuthorizationError,
  AuthorizationException,
  ConnectionError,
  ConnectionException,
  CoreException,
  Failure,
  PasswordRestoreRequiredError,
  PasswordRestoreRequiredException,
  Response,
  Success,
  UnknownError
} from '@sdk/sdk';

import { AuthRepository } from '../repositories/auth.repository';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResendCodeUseCase {
  constructor(private _repository: AuthRepository) {}

  async execute(email: string): Promise<Response<boolean, LoginError>> {
    const result = await this._repository.resendCode(email);
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(true);
  }

  private _exceptionToError(exception: CoreException): LoginError {
    if (exception instanceof AuthorizationException) {
      return new LoginAuthorizationError();
    }
    if (exception instanceof ConnectionException) {
      return new LoginConnectionError();
    }
    if (exception instanceof PasswordRestoreRequiredException) {
      return new LoginPasswordRestoreRequiredError();
    }
    throw new UnknownError();
  }
}

export abstract class LoginError {}
export class LoginAuthorizationError
  extends AuthorizationError
  implements LoginError {}
export class LoginPasswordRestoreRequiredError
  extends PasswordRestoreRequiredError
  implements LoginError {}
export class LoginConnectionError
  extends ConnectionError
  implements LoginError {}
