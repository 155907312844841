import {
  CoreError,
  CoreException,
  Failure,
  NotFoundError,
  Response,
  Success,
  UnknownError,
  UnknownException
} from '@sdk/sdk';

import { Injectable } from '@angular/core';
import { Auth } from '@core/core';
import { AuthRepository } from '../repositories/auth.repository';

@Injectable({ providedIn: 'root' })
export class GetLoggedUserUsecase {
  constructor(private _repository: AuthRepository) {}

  async execute(): Promise<Response<Auth, GetLoggedAccountError>> {
    const result = await this._repository.getLoggedUser();
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(result.value);
  }

  private _exceptionToError(exception: CoreException): GetLoggedAccountError {
    if (exception instanceof UnknownException) {
      return new UnknownError();
    }

    if (exception instanceof NotFoundError) {
      return new GetLoggedAccountNotFoundError();
    }

    throw new Error(`Unkown exception`);
  }
}

export abstract class GetLoggedAccountError extends Error implements CoreError {}

export class GetLoggedAccountNotFoundError extends NotFoundError implements GetLoggedAccountError {}
