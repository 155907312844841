<div class="overlay" *ngIf="initializing"><div class="spinner"></div></div>
<div class="title" i18n>Hello again!</div>
<div class="subtitle" i18n>Log in to your account</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="inputs">
    <div class="input-container with-prefix">
      <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
      <input
        type="email"
        i18n-placeholder
        placeholder="Email"
        formControlName="email"
        #email
        autocomplete="email" />
    </div>
    <div class="input-container with-prefix with-suffix">
      <fa-icon [icon]="['fas', 'lock-alt']"></fa-icon>
      <input
        type="password"
        i18n-placeholder
        placeholder="Password"
        formControlName="password"
        #password
        autocomplete="current-password" />
      <fa-icon
        [icon]="['fas', passToggleIcon]"
        style="cursor: pointer; pointer-events: initial"
        (click)="showHidePassword()"
        i18n-title
        title="Show/hide"></fa-icon>
    </div>
    <a
      class="forgot-pass"
      routerLink="restore-password"
      [queryParams]="{ email: emailValue }"
      i18n
      >Forgot password?</a
    >
  </div>
  <div class="buttons">
    <button
      class="secondary-button"
      type="button"
      *ngIf="isDevMode"
      routerLink="/create/account">
      <fa-icon [icon]="['fas', 'user-plus']"></fa-icon>
      <span i18n>Create account</span>
    </button>
    <span class="spacer"></span>
    <button
      class="rounded-button accent"
      *ngIf="!isLoading"
      [disabled]="!isFormValid">
      <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
      <span i18n>Continue</span>
    </button>
    <div class="spinner" *ngIf="isLoading"></div>
  </div>
  <div class="error" *ngIf="hasError">{{ errorMessage }}</div>
</form>
