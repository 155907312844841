import { Organization } from './organization';

export class UserAccount {
  name: string;
  email: string;
  organization: Organization;
  avatar?: string;

  constructor({ name, email, organization, avatar }: UserAccountData) {
    this.name = name;
    this.email = email;
    this.avatar = avatar;
    this.organization = organization;
  }
}

export interface UserAccountData {
  name: string;
  email: string;
  organization: Organization;
  avatar?: string;
}
