import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/ui/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.name === 'production' || environment.name === 'development') {
  Sentry.init({
    dsn: 'https://c504d865a2014ccc8b708cc11e9c4e1c@o72237.ingest.us.sentry.io/4507657515565056',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        networkDetailAllowUrls: ['https://cognito-idp.us-east-1.amazonaws.com/'],
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/sytex\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: environment.name
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
