import { Mapper } from 'src/app/sdk/sdk';
import { UserAccount } from '../auth';
import { LocalOrganizationMapper } from './local-organization.mapper';
import { LocalUserAccountMap } from './local-user-account.map';

export class LocalUserAccountMapper extends Mapper<
  UserAccount,
  LocalUserAccountMap
> {
  localOrganizationMapper = new LocalOrganizationMapper();

  fromMap(map: LocalUserAccountMap): UserAccount {
    return new UserAccount({
      email: map.email,
      name: map.name,
      avatar: map.avatar,
      organization: this.localOrganizationMapper.fromMap(map.organization),
    });
  }

  toMap(userAccount: UserAccount): LocalUserAccountMap {
    return {
      email: userAccount.email,
      name: userAccount.name,
      avatar: userAccount.avatar,
      organization: this.localOrganizationMapper.toMap(
        userAccount.organization
      ),
    };
  }
}
