<div class="content">
  <div class="cols">
    <div class="main">
      <div class="spacer"></div>
      <div class="center">
        <router-outlet></router-outlet>
      </div>
      <div class="spacer"></div>
    </div>
    <div class="right">
      <div class="decoration">
        <img class="bottom-left" src="assets/col-bot-left.svg" />
        <img class="bottom-right" src="assets/col-bot-right.svg" />
      </div>
      <div class="sytex-logo"><img src="assets/sytex-logo.svg" /></div>
      <div class="col-content"></div>
      <div class="bottom-image"></div>
    </div>
  </div>
</div>
