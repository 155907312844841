import {
  CheckValidSessionUseCase,
  ClearAccountsUseCase,
  GetUserAccountsUseCase,
  RemoveAccountUseCase,
  UserAccountData
} from '@core/core';
import { Component, OnInit } from '@angular/core';

import { OrganizationRedirectHelper } from '../organization-list/organization-redirect-helper';
import { Router } from '@angular/router';
import { Success } from '@sdk/sdk';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {
  constructor(
    private _checkSessionUseCase: CheckValidSessionUseCase,
    private _getUserAccountsUseCase: GetUserAccountsUseCase,
    private _clearAccountsUseCase: ClearAccountsUseCase,
    private _removeAccountsUseCase: RemoveAccountUseCase,
    private _organizationUrlHelper: OrganizationRedirectHelper,
    private _router: Router
  ) {}

  accounts: UserAccountData[] = [];

  async ngOnInit(): Promise<void> {
    const hasSession = await this._checkHasSession();
    if (hasSession) {
      this._router.navigate(['login', 'organizations'], {
        queryParamsHandling: 'merge'
      });
      return;
    }

    this._getUserAccounts();
  }

  private async _getUserAccounts(): Promise<void> {
    const accountsResult = await this._getUserAccountsUseCase.execute();

    if (accountsResult.value.length == 0) {
      this._router.navigate(['login'], { queryParamsHandling: 'merge' });
      return;
    }

    this.accounts = accountsResult.value;
  }

  private async _checkHasSession(): Promise<boolean> {
    const result = await this._checkSessionUseCase.execute();
    if (result instanceof Success) {
      return true;
    }

    return false;
  }

  selectAccount(acc: UserAccountData): void {
    this._router.navigate(['login'], {
      queryParams: {
        email: acc.email,
        r: this._organizationUrlHelper.getOrganizationUrl(acc.organization)
      },
      queryParamsHandling: 'merge'
    });
  }

  async clearAccountList(): Promise<void> {
    await this._clearAccountsUseCase.execute();
    this.accounts = [];
    this._router.navigate(['login'], { queryParamsHandling: 'merge' });
  }

  addAccount(): void {
    this._router.navigate(['login'], {
      queryParams: { new: 1 },
      queryParamsHandling: 'merge'
    });
  }

  async removeAccount(acc: UserAccountData): Promise<void> {
    const result = await this._removeAccountsUseCase.execute(acc);
    if (result instanceof Success) {
      this._getUserAccounts();
    }
  }
}
