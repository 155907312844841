import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ValidateCodeMismatchExceptionError,
  ValidateEmailUseCase,
  ValidateError
} from '@core/auth/application/usecases/validate-email-usecase';
import { Failure, Success } from '@sdk/sdk';

import { ActivatedRoute } from '@angular/router';
import { ResendCodeUseCase } from '@core/auth/application/usecases/resend-code-usecase';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit {
  redirectTo: string | null = null;
  name!: string;
  email!: string;
  form: FormGroup;
  isLoadingValidate = false;
  isLoadingResend = false;
  errorMessage = '';

  constructor(
    private _validateEmailUseCase: ValidateEmailUseCase,
    private _resendCodeUseCase: ResendCodeUseCase,
    private _route: ActivatedRoute
  ) {
    this.form = new FormGroup({
      code: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.redirectTo = this._route.snapshot.queryParamMap.get('redirect');
    this.name = this._route.snapshot.queryParamMap.get('name')!;
    this.email = this._route.snapshot.queryParamMap.get('email')!;
  }

  async resendCode(): Promise<void> {
    this.isLoadingResend = true;
    await this._resendCodeUseCase.execute(this.email);
    this.isLoadingResend = false;
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoadingValidate = true;
      const result = await this._validateEmailUseCase.execute(this.email, this.form.value.code);
      this.isLoadingValidate = false;
      if (result instanceof Success) {
        window.location.href = this.redirectTo!;
      }

      if (result instanceof Failure) {
        this.showErrorMessage(result.error);
      }
    }
  }

  showErrorMessage(error: ValidateError): void {
    if (error instanceof ValidateCodeMismatchExceptionError) {
      this.errorMessage = $localize`Invalid or expired code. Please verify the code or request a new one clicking resend code.`;
    }
  }
}
