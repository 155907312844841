import {
  ConnectionException,
  CoreException,
  Failure,
  InvalidUserError,
  Response,
  Success,
  UnknownError,
  UserAlreadyExistsException
} from '@sdk/sdk';

import { AuthRepository } from '../repositories/auth.repository';
import { Injectable } from '@angular/core';
import { SaveUserUsecase } from './save-user.usecase';

@Injectable({
  providedIn: 'root'
})
export class RegisterUseCase {
  constructor(
    private _repository: AuthRepository,
    private _saveUserUsecase: SaveUserUsecase
  ) {}

  async execute(
    email: string,
    password: string,
    name: string
  ): Promise<Response<boolean, RegisterError>> {
    const result = await this._repository.register(email, password);
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    await this._saveUserUsecase.execute(email, name, undefined);
    return new Success(true);
  }

  private _exceptionToError(exception: CoreException): RegisterError {
    if (exception instanceof ConnectionException) {
      return new RegisterConnectionError();
    }

    if (exception instanceof UserAlreadyExistsException) {
      return new RegisterUserAlreadyExistsError();
    }
    throw new UnknownError();
  }
}

export abstract class RegisterError {}

export class RegisterUserAlreadyExistsError
  extends InvalidUserError
  implements RegisterError {}

export class RegisterConnectionError
  extends InvalidUserError
  implements RegisterError {}
