<div class="title" i18n>Hello again!</div>
<div class="subtitle" i18n>Choose an account to continue.</div>
<div class="account-list">
  <div class="account" *ngFor="let account of accounts" (click)="selectAccount(account)">
    <div class="avatar without-image" *ngIf="!account.organization.avatar">
      <fa-icon [icon]="['fas', 'building']"></fa-icon>
    </div>
    <div
      class="avatar"
      *ngIf="account.organization.avatar"
      [style.background-image]="'url(' + account.organization.avatar + ')'"></div>
    <div class="info">
      <div class="name">{{ account.organization.name }}</div>
      <div class="email">{{ account.email }}</div>
    </div>
    <fa-icon
      [icon]="['far', 'minus-circle']"
      i18n-title
      title="Remove from list"
      (click)="removeAccount(account)"></fa-icon>
  </div>
</div>
<div class="buttons">
  <button class="secondary-button" (click)="addAccount()">
    <fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
    <span i18n>Use another account</span>
  </button>
</div>
