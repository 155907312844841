<div class="step">Paso X de Y</div>
<div class="title" i18n>Welcome on board</div>
<div class="subtitle" i18n>
  Join an existing organization or create your own.
</div>
<div class="buttons">
  <button class="rounded-button" routerLink="../join">
    <fa-icon [icon]="['fas', 'user-edit']"></fa-icon>
    <span i18n>Join</span>
  </button>
  <button class="rounded-button accent" routerLink="../organization">
    <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
    <span i18n>Create</span>
  </button>
</div>
