export const environment = {
production: true,
localDevUrl: 'http://localhost/',
cognito: {
user_pool_id: 'us-east-1_ZPPSiSJNu',
client_id: '6qhjddn8tos35e59hk5gla3o4p',
prisma_url: 'https://we8hti241h.execute-api.us-east-1.amazonaws.com/prod',
region: 'us-east-1',
cookie_domain: '.sytex.io'
},
i18n: {
cookie_name: 'sytex_language'
},
name: "production"
};