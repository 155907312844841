import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  RestorePasswordAuthorizationError,
  RestorePasswordConnectionError,
  RestorePasswordLimitExceededError,
  RestorePasswordUseCase
} from '@core/auth/application/usecases/restore-password.usecase';

import { CheckValidSessionUseCase } from '@core/core';
import { Success } from '@sdk/sdk';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  hasError = false;
  errorMessage = '';
  isLoading = false;

  @ViewChild('email') emailInput!: ElementRef;

  public get isFormValid(): boolean {
    return this.form.valid;
  }

  public get emailValue(): string | undefined {
    return this.form.value.email ? this.form.value.email : undefined;
  }

  constructor(
    private _checkSessionUseCase: CheckValidSessionUseCase,
    private _restorePasswordUseCase: RestorePasswordUseCase,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  async ngOnInit(): Promise<void> {
    const hasSession = await this._checkHasSession();
    if (hasSession) {
      this._router.navigate(['login', 'organizations']);
      return;
    }

    const email = this._route.snapshot.queryParamMap.get('email');
    if (email != null) {
      this.form.patchValue({ email: email });
    }
  }

  onSubmit(): void {
    if (!this.isFormValid) {
      return;
    }

    if (this.isLoading) {
      return;
    }

    this._restorePassword(this.form.value.email);
  }

  private async _checkHasSession(): Promise<boolean> {
    const result = await this._checkSessionUseCase.execute();
    if (result instanceof Success) {
      return true;
    }

    return false;
  }

  private async _restorePassword(email: string): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    this.errorMessage = '';
    this.form.disable();

    const response = await this._restorePasswordUseCase.execute(email);

    this.isLoading = false;
    this.form.enable();

    if (response instanceof Success) {
      this._router.navigate(['login', 'validate-password'], {
        queryParams: { email: email }
      });
      return;
    }

    // We know we have a failure
    this.hasError = true;

    // Set error message based on error type
    if (response.error instanceof RestorePasswordConnectionError) {
      this.errorMessage = $localize`Connection error`;
      return;
    }

    if (response.error instanceof RestorePasswordAuthorizationError) {
      this.errorMessage = $localize`Invalid email account`;
      return;
    }

    if (response.error instanceof RestorePasswordLimitExceededError) {
      this.errorMessage = $localize`Attempt limit exceeded, please try again later`;
      return;
    }

    this.errorMessage = $localize`Error restoring password, please try again later. If the problem persists, contact support.`;
    return;
  }
}
