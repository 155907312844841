<div class="decoration">
  <img class="top-left" src="assets/body-top-left.svg" />
  <img class="bottom-right" src="assets/body-bot-right.svg" />
</div>

<div class="content">
  <div class="top" *ngIf="!isDevMode">
    <app-language-selector></app-language-selector>
  </div>
  <div class="center">
    <div class="sytex-logo"><img src="assets/sytex-logo.svg" /></div>
    <router-outlet></router-outlet>
    <div class="footer">
      <img src="assets/connected-world.svg" />
      <div class="right">
        <div class="phrase" i18n>
          Let's make a more connected world together
        </div>
        <div class="hashtag">
          <a
            href="https://www.linkedin.com/feed/hashtag/?keywords={{ hashtag }}"
            target="_blank"
            >#{{ hashtag }}</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
