import * as Sentry from "@sentry/angular";

import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import {
  AuthRepository,
  I18nService,
  LocalUserAccountsRepository,
  OrganizationsRepository,
  UserAccountsRepository
} from '../core/core';

import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { Amplify } from 'aws-amplify';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { ApiAuthRepository } from '../core/auth/infrastructure/api-auth.repository';
import { ApiOrganizationsRepository } from '../core/auth/infrastructure/api-organizations.repository';
import { AppComponent } from './app.component';
import { CreationModule } from './creation/creation.module';
import { IconsModule } from './icons/icons.module';
import { LoginModule } from './login/login.module';
import { RoutingModule } from './routing/routing.module';
import { SharedModule } from './shared/shared.module';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

const cookieConfig: NgcCookieConsentConfig = {
  enabled: true,
  autoOpen: true,
  autoAttach: true,
  position: 'bottom-left',
  theme: 'classic',
  showLink: false,
  palette: {
    popup: {
      background: '#00CCCC',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#FFFFFF',
      text: '#5A7077',
      border: 'transparent'
    }
  },
  type: 'info',
  content: {
    message: $localize`We use essential cookies as well as session cookies to make Sytex work.`,
    dismiss: $localize`Accept`,
    policy: $localize`Cookie policy`
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CreationModule,
    HttpClientModule,
    IconsModule,
    LoginModule,
    RoutingModule,
    SharedModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    CookieService,
    { provide: AuthRepository, useExisting: ApiAuthRepository },
    {
      provide: OrganizationsRepository,
      useExisting: ApiOrganizationsRepository
    },
    {
      provide: UserAccountsRepository,
      useExisting: LocalUserAccountsRepository,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private _: I18nService // Force initialization on app start
  ) {
    Amplify.configure({
      Auth: {
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        region: environment.cognito.region,
        userPoolId: environment.cognito.user_pool_id,
        userPoolWebClientId: environment.cognito.client_id,
        cookieStorage: {
          domain: environment.cognito.cookie_domain,
          path: '/',
          secure: true,
          sameSite: 'lax',
          expires: 30
        }
      }
    });
  }
}
