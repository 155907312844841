import { CoreException, Response } from '@sdk/sdk';
import { Auth } from '../../auth';

export abstract class AuthRepository {
  abstract login(
    email: string,
    password: string
  ): Promise<Response<Auth, CoreException>>;

  abstract save(auth: Auth): Promise<Response<Auth, CoreException>>;

  abstract getLoggedUser(): Promise<Response<Auth, CoreException>>;

  abstract logOut(): Promise<Response<boolean, CoreException>>;

  abstract restorePassword(
    email: string
  ): Promise<Response<boolean, CoreException>>;

  abstract restorePasswordSubmit(
    email: string,
    password: string,
    code: string
  ): Promise<Response<boolean, CoreException>>;

  abstract changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<Response<boolean, CoreException>>;

  abstract completeNewPassword(
    password: string
  ): Promise<Response<boolean, CoreException>>;
  
  abstract register(username: string, password: string): Promise<Response<boolean, CoreException>>;

  abstract validateEmail(username: string, code: string): Promise<Response<boolean, CoreException>>;

  abstract resendCode(username: string): Promise<Response<boolean, CoreException>>;

  abstract hasValidSession(): Promise<Response<boolean, CoreException>>;
}
