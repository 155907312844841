import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ChangePasswordInvalidPasswordError, PasswordChallengeUsecase } from '@core/core';
import { LimitExceededError, PasswordValidator, Success } from '@sdk/sdk';

import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-challenge',
  templateUrl: './password-challenge.component.html',
  styleUrls: ['./password-challenge.component.scss']
})
export class PasswordChallengeComponent {
  form: FormGroup;
  hasError = false;
  errorMessage = '';
  isLoading = false;

  get passwordsValid(): boolean {
    const mismatch = this.form.errors ? this.form.errors['passwordMissmatch'] : false;
    const policy1 = this.form.get('new_password')!.errors
      ? this.form.get('new_password')!.errors!['passwordInvalid']
      : false;
    const policy2 = this.form.get('new_password_repeat')!.errors
      ? this.form.get('new_password_repeat')!.errors!['passwordInvalid']
      : false;

    return !mismatch && !policy1 && !policy2;
  }

  get isFormValid(): boolean {
    return this.form.valid;
  }

  get passwordFormField(): AbstractControl | null {
    return this.form.get('new_password');
  }

  get matchesLength(): boolean {
    return this.passwordFormField?.value?.match(/.{8,}/);
  }

  get matchesNumbers(): boolean {
    return this.passwordFormField?.value?.match(/[0-9]/);
  }

  get matchesLowercase(): boolean {
    return this.passwordFormField?.value?.match(/[a-z]/);
  }

  get matchesUppercase(): boolean {
    return this.passwordFormField?.value?.match(/[A-Z]/);
  }

  get matchesSpecialChars(): boolean {
    return this.passwordFormField?.value?.match(/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/);
  }

  constructor(
    private _passwordChallengeUsecase: PasswordChallengeUsecase,
    private _router: Router,
    private _passwordValidator: PasswordValidator
  ) {
    this.form = new FormGroup(
      {
        new_password: new FormControl('', [Validators.required, this._passwordValid.bind(this)]),
        new_password_repeat: new FormControl('', [Validators.required, this._passwordValid.bind(this)])
      },
      { validators: this._passwordsMatch }
    );
  }

  async onSubmit(): Promise<void> {
    if (!this.isFormValid) {
      return;
    }
    if (this.isLoading) {
      return;
    }
    this._changePasswordSubmit();
  }

  private async _changePasswordSubmit(): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    this.errorMessage = '';
    this.form.disable();

    const newPassword = this.form.get('new_password')?.value;
    const newPasswordRepeat = this.form.get('new_password_repeat')?.value;

    const response = await this._passwordChallengeUsecase.execute(newPassword, newPasswordRepeat);

    this.isLoading = false;
    this.form.enable();

    if (response instanceof Success) {
      this._router.navigate(['login']);
      return;
    }

    // We know we have a failure
    this.hasError = true;

    // Set error message based on error type
    if (response.error instanceof ChangePasswordInvalidPasswordError) {
      this.errorMessage = $localize`Password did not conform with policy`;
      return;
    }

    if (response.error instanceof LimitExceededError) {
      this.errorMessage = $localize`Attempt limit exceeded, please try again later`;
      return;
    }

    this.errorMessage = $localize`An error occurred, please try again later. If the problem persists, contact support.`;
  }

  private _passwordsMatch(control: AbstractControl): ValidationErrors | null {
    const newPassword = control.get('new_password')?.value;
    const newPasswordRepeat = control.get('new_password_repeat')?.value;

    return newPassword !== newPasswordRepeat ? { passwordMissmatch: true } : null;
  }

  private _passwordValid(control: AbstractControl): ValidationErrors | null {
    const password = control.value;
    const isValid = this._passwordValidator.passwordStreghtIsValid(password);
    return isValid ? null : { passwordInvalid: true };
  }
}
