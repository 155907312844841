import {
  CoreException,
  Failure,
  NotFoundException,
  Response,
  Success
} from '@sdk/sdk';

import { Injectable } from '@angular/core';
import { LocalUserAccountMap } from './local-user-account.map';
import { LocalUserAccountMapper } from './local-user-account.mapper';
import { UserAccount } from '../domain/user-account';
import { UserAccountsRepository } from '../auth';

@Injectable({
  providedIn: 'root'
})
export class LocalUserAccountsRepository implements UserAccountsRepository {
  localUserAccountMapper = new LocalUserAccountMapper();

  async getAll(): Promise<Response<UserAccount[], CoreException>> {
    return Promise.resolve(new Success(this._getUserAccounts()));
  }

  async clearAll(): Promise<Response<boolean, CoreException>> {
    localStorage.removeItem('sytex-account-list');
    return new Success(true);
  }

  save(
    userAccount: UserAccount
  ): Promise<Response<UserAccount, CoreException>> {
    let userAccounts = this._getUserAccounts();
    const exists = userAccounts.some(
      elem =>
        elem.email === userAccount.email &&
        elem.organization.id === userAccount.organization.id
    );

    if (!exists) {
      userAccounts = [...userAccounts, userAccount];
      localStorage.setItem(
        'sytex-account-list',
        JSON.stringify(
          userAccounts.map(item => this.localUserAccountMapper.toMap(item))
        )
      );
    }

    return Promise.resolve(new Success(userAccount));
  }

  async removeAccount(
    email: string,
    organizationId: string
  ): Promise<Response<boolean, CoreException>> {
    let accounts = this._getUserAccounts();
    const exist = accounts.find(
      account =>
        account.email === email && account.organization.id === organizationId
    );
    if (!exist) {
      return new Failure(new NotFoundException());
    }
    accounts = accounts.filter(
      account =>
        !(account.email === email && account.organization.id === organizationId)
    );
    localStorage.setItem(
      'sytex-account-list',
      JSON.stringify(
        accounts.map(item => this.localUserAccountMapper.toMap(item))
      )
    );
    return new Success(true);
  }

  private _getUserAccounts(): UserAccount[] {
    const userAccountsString = localStorage.getItem('sytex-account-list');
    if (!userAccountsString) {
      return [];
    }
    const userAccountsJson = JSON.parse(userAccountsString);
    return userAccountsJson
      .map((item: LocalUserAccountMap) =>
        this.localUserAccountMapper.fromMap(item)
      )
      .sort((accountA: UserAccount, accountB: UserAccount) =>
        `${accountA.email}${accountA.organization.name}` >
        `${accountB.email}${accountB.organization.name}`
          ? 1
          : -1
      );
  }
}
