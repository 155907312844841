import {
  AuthorizationError,
  CodeMismatchError,
  ConnectionError,
  InvalidPasswordError,
  InvalidTokenError,
  InvalidUserError,
  LimitExceededError,
  NotFoundError,
  ServerError,
  UnknownError
} from './core.error';

export abstract class CoreException extends Error {}

export class ConnectionException extends ConnectionError implements CoreException {}

export class AuthorizationException extends AuthorizationError implements CoreException {}

export class InvalidPasswordException extends InvalidPasswordError implements CoreException {}

export class PasswordRestoreRequiredException extends InvalidPasswordError implements CoreException {}

export class LimitExceededException extends LimitExceededError implements CoreException {}

export class CodeMismatchException extends CodeMismatchError implements CoreException {}

export class ServerException extends ServerError implements CoreException {}

export class NotFoundException extends NotFoundError implements CoreException {}

export class InvalidTokenException extends InvalidTokenError implements CoreException {}

export class UserAlreadyExistsException extends InvalidUserError implements CoreException {}

export class UserNotConfirmedException extends InvalidUserError implements CoreException {}

export class ExpiredCodeException extends UnknownError implements CoreException {}

export class UnknownException extends UnknownError implements CoreException {}
