import {
  CoreException,
  Failure,
  NotFoundError,
  NotFoundException,
  Response,
  Success,
  UnknownError
} from '@sdk/sdk';

import { Injectable } from '@angular/core';
import { UserAccount } from '@core/core';
import { UserAccountsRepository } from '../repositories/user-accounts.repository';

@Injectable({
  providedIn: 'root'
})
export class RemoveAccountUseCase {
  constructor(private _userAccountRepository: UserAccountsRepository) {}

  async execute(
    account: UserAccount
  ): Promise<Response<boolean, RemoveAccountError>> {
    const result = await this._userAccountRepository.removeAccount(
      account.email,
      account.organization.id
    );
    if (result instanceof Failure) {
      const error = this._exceptionToError(result.error);
      return new Failure(error);
    }
    return new Success(result.value);
  }

  private _exceptionToError(exception: CoreException): RemoveAccountError {
    if (exception instanceof NotFoundException) {
      return new RemoveAccountNotFoundError();
    }
    return new UnknownError();
  }
}

export abstract class RemoveAccountError {}
export class RemoveAccountNotFoundError
  extends NotFoundError
  implements RemoveAccountError {}
