import { I18nService, SystemLanguage } from 'src/app/core/core';

import { Component } from '@angular/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
  constructor(private _i18nService: I18nService) {
    // Empty
  }

  public get languageList(): SystemLanguage[] {
    return this._i18nService
      .getLanguageList()
      .filter(l => l.id != this._i18nService.getCurrentLanguage().id);
  }

  changeLanguage(l: SystemLanguage): void {
    this._i18nService.setCurrentLanguage(l.id);
  }
}
