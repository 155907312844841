<div class="title" i18n>Change your password</div>
<div class="subtitle" i18n>
  This password is used across all your organizations.
</div>
<form [formGroup]="form" *ngIf="!isSuccess" (ngSubmit)="onSubmit()">
  <div class="inputs">
    <div class="input-container with-prefix">
      <fa-icon [icon]="['fas', 'lock-alt']"></fa-icon>
      <input
        type="password"
        i18n-placeholder
        placeholder="Current password"
        formControlName="old_password" />
    </div>
    <div class="row">
      <div class="input-container with-prefix">
        <fa-icon [icon]="['fas', 'lock-alt']"></fa-icon>
        <input
          type="password"
          i18n-placeholder
          placeholder="New password"
          formControlName="new_password" />
      </div>
    </div>
    <div class="row">
      <div class="input-container with-prefix">
        <fa-icon [icon]="['fas', 'lock-alt']"></fa-icon>
        <input
          type="password"
          i18n-placeholder
          placeholder="Repeat new password"
          formControlName="new_password_repeat" />
      </div>
      <fa-icon
        class="pass-check"
        [icon]="['fas', 'check-circle']"
        *ngIf="passwordsValid"></fa-icon>
    </div>
  </div>
  <div class="bottom">
    <div class="password-policy">
      <div class="title" i18n>Password security</div>
      <div class="policy">
        <ng-container
          *ngIf="passwordFormField?.value.length > 0; else noPassword">
          <ng-container *ngIf="matchesLength; else notValid">
            <fa-icon
              class="valid"
              [icon]="['far', 'check']"
              [fixedWidth]="true"></fa-icon>
          </ng-container>
        </ng-container>
        <span i18n>Minimum length 8 characters</span>
      </div>
      <div class="policy">
        <ng-container
          *ngIf="passwordFormField?.value.length > 0; else noPassword">
          <ng-container *ngIf="matchesNumbers; else notValid">
            <fa-icon
              class="valid"
              [icon]="['far', 'check']"
              [fixedWidth]="true"></fa-icon>
          </ng-container>
        </ng-container>
        <span i18n>At least 1 number</span>
      </div>
      <div class="policy">
        <ng-container
          *ngIf="passwordFormField?.value.length > 0; else noPassword">
          <ng-container *ngIf="matchesUppercase; else notValid">
            <fa-icon
              class="valid"
              [icon]="['far', 'check']"
              [fixedWidth]="true"></fa-icon>
          </ng-container>
        </ng-container>
        <span i18n>At least 1 uppercase letter</span>
      </div>
      <div class="policy">
        <ng-container
          *ngIf="passwordFormField?.value.length > 0; else noPassword">
          <ng-container *ngIf="matchesLowercase; else notValid">
            <fa-icon
              class="valid"
              [icon]="['far', 'check']"
              [fixedWidth]="true"></fa-icon>
          </ng-container>
        </ng-container>
        <span i18n>At least 1 lowercase letter</span>
      </div>
      <div class="policy">
        <ng-container
          *ngIf="passwordFormField?.value.length > 0; else noPassword">
          <ng-container *ngIf="matchesSpecialChars; else notValid">
            <fa-icon
              class="valid"
              [icon]="['far', 'check']"
              [fixedWidth]="true"></fa-icon>
          </ng-container>
        </ng-container>
        <span i18n>At least 1 special character</span>
      </div>
    </div>
    <div class="buttons">
      <button
        class="rounded-button"
        type="button"
        *ngIf="!isLoading"
        routerLink="../organizations">
        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
        <span i18n>Back</span>
      </button>
      <span class="spacer"></span>
      <button
        class="rounded-button accent"
        *ngIf="!isLoading"
        [disabled]="!isFormValid">
        <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
        <span i18n>Continue</span>
      </button>
      <div class="spinner" *ngIf="isLoading"></div>
    </div>
  </div>
  <div class="error" *ngIf="hasError">{{ errorMessage }}</div>
</form>
<div *ngIf="isSuccess">
  <div class="success" i18n>Password changed successfully</div>
  <div class="buttons">
    <div class="spacer"></div>
    <button class="rounded-button accent" routerLink="..">
      <fa-icon [icon]="['fas', 'check']"></fa-icon>
      <span i18n>Done</span>
    </button>
  </div>
</div>

<ng-template #noPassword>
  <fa-icon [icon]="['far', 'angle-right']" [fixedWidth]="true"></fa-icon>
</ng-template>
<ng-template #notValid>
  <fa-icon
    class="invalid"
    [icon]="['far', 'times']"
    [fixedWidth]="true"></fa-icon>
</ng-template>
