import { Injectable, isDevMode } from '@angular/core';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import {
  ApiErrorParser,
  CoreException,
  Failure,
  NotFoundException,
  Response,
  Success,
  UnknownException
} from 'src/app/sdk/sdk';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OrganizationsRepository } from '../auth';
import { Organization } from '../domain/organization';
import { ApiOrganizationMapper } from './api-organization.mapper';
import { ApiSessionService } from './api-session.service';
import { LocalOrganizationMap } from './local-organization.map';
import { LocalOrganizationMapper } from './local-organization.mapper';
import { OrganizationMap } from './organization-map';

@Injectable({
  providedIn: 'root'
})
export class ApiOrganizationsRepository implements OrganizationsRepository {
  mapper = new ApiOrganizationMapper();
  errorParser = new ApiErrorParser();
  sessionService = new ApiSessionService();
  localOrganizationMapper = new LocalOrganizationMapper();
  constructor(private _http: HttpClient) {}

  async getAll(): Promise<Response<Organization[], CoreException>> {
    try {
      const token = await this.sessionService.getToken();
      const result = await firstValueFrom(
        this._http
          .get<OrganizationMap[]>(`${environment.cognito.prisma_url}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .pipe(catchError(error => throwError(() => error)))
      );
      let organizations = result.map(organizationMap => this.mapper.fromMap(organizationMap));

      if (isDevMode()) {
        organizations = this._addDummyOrganization(organizations);
      }
      return new Success(organizations);
    } catch (error) {
      return new Failure(this.errorParser.parse(error));
    }
  }

  async save(organization: Organization): Promise<Response<Organization, CoreException>> {
    try {
      let organizations = this._getOrganizations();
      const exists = organizations.some(localOrganization => localOrganization.id == organization.id);

      if (!exists) {
        organizations = [...organizations, organization];
        localStorage.setItem(
          'sytex-organization-list',
          JSON.stringify(organizations.map(organization => this.localOrganizationMapper.toMap(organization)))
        );
      }
      return new Success(organization);
    } catch (error) {
      return new Failure(new UnknownException(`${error}`));
    }
  }

  async get(id: string): Promise<Response<Organization, CoreException>> {
    try {
      const organizations = this._getOrganizations();
      const organization = organizations.find(organization => organization.id == id);
      if (!organization) {
        return new Failure(new NotFoundException());
      }
      return new Success(organization);
    } catch (error) {
      return new Failure(new UnknownException(`${error}`));
    }
  }

  private _addDummyOrganization(organizations: Organization[]): Organization[] {
    return organizations.concat([
      new Organization({
        name: 'Local dev',
        url: new URL(environment.localDevUrl),
        id: '1'
      })
    ]);
  }

  private _getOrganizations(): Organization[] {
    const organizationsString = localStorage.getItem('sytex-organization-list');
    if (!organizationsString) {
      return [];
    }
    const userAccountsJson = JSON.parse(organizationsString);
    return userAccountsJson.map((organizationMap: LocalOrganizationMap) =>
      this.localOrganizationMapper.fromMap(organizationMap)
    );
  }
}
